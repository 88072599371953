import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from 'react-router';
import { AuthLayoutFooter } from './auth-layout-footer';
import { useIsDarkMode } from '@ui/themes/use-is-dark-mode';
import authBgSvg from './auth-bg.svg';
import { useTrans } from '@ui/i18n/use-trans';
import { useSettings } from '@ui/settings/use-settings';
export function AuthLayout({ heading, children, message }) {
    const { branding } = useSettings();
    const isDarkMode = useIsDarkMode();
    const { trans } = useTrans();
    return (_jsxs("main", { className: "flex h-screen flex-col items-center overflow-y-auto bg-alt px-14 pt-70 dark:bg-none md:px-10vw", style: { backgroundImage: isDarkMode ? undefined : `url("${authBgSvg}")` }, children: [_jsx(Link, { to: "/", className: "mb-40 block flex-shrink-0", "aria-label": trans({ message: 'Go to homepage' }), children: _jsx("img", { src: isDarkMode ? branding.logo_light : branding?.logo_dark, className: "m-auto block h-42 w-auto", alt: "" }) }), _jsxs("div", { className: "mx-auto w-full max-w-440 rounded-lg bg px-40 pb-32 pt-40 shadow md:shadow-xl", children: [heading && _jsx("h1", { className: "mb-20 text-xl", children: heading }), children] }), message && _jsx("div", { className: "mt-36 text-sm", children: message }), _jsx(AuthLayoutFooter, {})] }));
}

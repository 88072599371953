import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { findBestPrice } from './find-best-price';
import { Fragment, memo } from 'react';
import { Trans } from '@ui/i18n/trans';
export const UpsellLabel = memo(({ products }) => {
    const upsellPercentage = calcHighestUpsellPercentage(products);
    if (upsellPercentage <= 0) {
        return null;
    }
    return (_jsx(Fragment, { children: _jsxs("span", { className: "font-medium text-positive-darker", children: [' ', "(", _jsx(Trans, { message: "Save up to :percentage%", values: { percentage: upsellPercentage } }), ")"] }) }));
});
function calcHighestUpsellPercentage(products) {
    if (!products?.length)
        return 0;
    const decreases = products.map(product => {
        if (product.hidden)
            return 0;
        const monthly = findBestPrice('monthly', product.prices);
        const yearly = findBestPrice('yearly', product.prices);
        if (!monthly || !yearly)
            return 0;
        // monthly plan per year amount
        const monthlyAmount = monthly.amount * 12;
        const yearlyAmount = yearly.amount;
        const savingsPercentage = Math.round(((monthlyAmount - yearlyAmount) / monthlyAmount) * 100);
        if (savingsPercentage > 0 && savingsPercentage <= 200) {
            return savingsPercentage;
        }
        return 0;
    });
    return Math.max(Math.max(...decreases), 0);
}

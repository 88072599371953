import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AccountSettingsPanel } from '../account-settings-panel';
import { Trans } from '@ui/i18n/trans';
import { AccountSettingsId } from '@common/auth/ui/account-settings/account-settings-sidenav';
import { useUserSessions, } from '@common/auth/ui/account-settings/sessions-panel/requests/use-user-sessions';
import { ComputerIcon } from '@ui/icons/material/Computer';
import { SmartphoneIcon } from '@ui/icons/material/Smartphone';
import { TabletIcon } from '@ui/icons/material/Tablet';
import { FormattedRelativeTime } from '@ui/i18n/formatted-relative-time';
import { Fragment } from 'react';
import { ProgressCircle } from '@ui/progress/progress-circle';
import { useLogoutOtherSessions } from '@common/auth/ui/account-settings/sessions-panel/requests/use-logout-other-sessions';
import { usePasswordConfirmedAction } from '@common/auth/ui/confirm-password/use-password-confirmed-action';
import { Button } from '@ui/buttons/button';
import { toast } from '@ui/toast/toast';
import { message } from '@ui/i18n/message';
export function SessionsPanel() {
    const { data, isLoading } = useUserSessions();
    const logoutOther = useLogoutOtherSessions();
    const { withConfirmedPassword, isLoading: checkingPasswordStatus } = usePasswordConfirmedAction({ needsPassword: true });
    const sessionList = (_jsx("div", { className: "max-h-400 overflow-y-auto", children: data?.sessions?.map(session => (_jsx(SessionItem, { session: session }, session.id))) }));
    return (_jsxs(AccountSettingsPanel, { id: AccountSettingsId.Sessions, title: _jsx(Trans, { message: "Active sessions" }), children: [_jsx("p", { className: "text-sm", children: _jsx(Trans, { message: "If necessary, you may log out of all of your other browser sessions across all of your devices. Your recent sessions are listed below. If you feel your account has been compromised, you should also update your password." }) }), _jsx("div", { className: "my-30", children: isLoading ? (_jsx("div", { className: "min-h-60", children: _jsx(ProgressCircle, { isIndeterminate: true }) })) : (sessionList) }), _jsx(Button, { variant: "outline", color: "primary", disabled: checkingPasswordStatus || logoutOther.isPending, onClick: () => {
                    withConfirmedPassword(password => {
                        logoutOther.mutate({ password: password }, {
                            onSuccess: () => {
                                toast(message('Logged out other sessions.'));
                            },
                        });
                    });
                }, children: _jsx(Trans, { message: "Logout other sessions" }) })] }));
}
function SessionItem({ session }) {
    return (_jsxs("div", { className: "mb-14 flex items-start gap-14 text-sm", children: [_jsx("div", { className: "flex-shrink-0 text-muted", children: _jsx(DeviceIcon, { device: session.device_type, size: "lg" }) }), _jsxs("div", { className: "flex-auto", children: [_jsxs("div", { children: [_jsx(ValueOrUnknown, { children: session.platform }), " -", ' ', _jsx(ValueOrUnknown, { children: session.browser })] }), _jsxs("div", { className: "my-4 text-xs", children: [session.city, ", ", session.country] }), _jsxs("div", { className: "text-xs", children: [_jsx(IpAddress, { session: session }), " - ", _jsx(LastActive, { session: session })] })] })] }));
}
function DeviceIcon({ device, size }) {
    switch (device) {
        case 'mobile':
            return _jsx(SmartphoneIcon, { size: size });
        case 'tablet':
            return _jsx(TabletIcon, { size: size });
        default:
            return _jsx(ComputerIcon, { size: size });
    }
}
function LastActive({ session }) {
    if (session.is_current_device) {
        return (_jsx("span", { className: "text-positive", children: _jsx(Trans, { message: "This device" }) }));
    }
    return _jsx(FormattedRelativeTime, { date: session.last_active });
}
function IpAddress({ session }) {
    if (session.ip_address) {
        return _jsx("span", { children: session.ip_address });
    }
    else if (session.token) {
        return _jsx(Trans, { message: "API Token" });
    }
    return _jsx(Trans, { message: "Unknown IP" });
}
function ValueOrUnknown({ children }) {
    return children ? (_jsx(Fragment, { children: children })) : (_jsx(Trans, { message: "Unknown" }));
}

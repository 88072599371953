import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useForm } from 'react-hook-form';
import { useId } from 'react';
import { AccountSettingsPanel } from '../account-settings-panel';
import { Button } from '@ui/buttons/button';
import { Form } from '@ui/forms/form';
import { FormTextField } from '@ui/forms/input-field/text-field/text-field';
import { useUpdateAccountDetails } from './update-account-details';
import { Trans } from '@ui/i18n/trans';
import { useUploadAvatar } from '../avatar/upload-avatar';
import { useRemoveAvatar } from '../avatar/remove-avatar';
import { FormImageSelector } from '@common/uploads/components/image-selector';
import { FileUploadProvider } from '@common/uploads/uploader/file-upload-provider';
import { AccountSettingsId } from '@common/auth/ui/account-settings/account-settings-sidenav';
export function BasicInfoPanel({ user }) {
    const uploadAvatar = useUploadAvatar({ user });
    const removeAvatar = useRemoveAvatar({ user });
    const formId = useId();
    const form = useForm({
        defaultValues: {
            first_name: user.first_name || '',
            last_name: user.last_name || '',
            image: user.image,
        },
    });
    const updateDetails = useUpdateAccountDetails(user.id, form);
    return (_jsx(AccountSettingsPanel, { id: AccountSettingsId.AccountDetails, title: _jsx(Trans, { message: "Update name and profile image" }), actions: _jsx(Button, { type: "submit", variant: "flat", color: "primary", form: formId, disabled: updateDetails.isPending || !form.formState.isValid, children: _jsx(Trans, { message: "Save" }) }), children: _jsxs(Form, { form: form, className: "flex flex-col items-center gap-40 md:flex-row md:gap-80", onSubmit: newDetails => {
                updateDetails.mutate(newDetails);
            }, id: formId, children: [_jsxs("div", { className: "w-full flex-auto", children: [_jsx(FormTextField, { className: "mb-24", name: "first_name", label: _jsx(Trans, { message: "First name" }) }), _jsx(FormTextField, { name: "last_name", label: _jsx(Trans, { message: "Last name" }) })] }), _jsx(FileUploadProvider, { children: _jsx(FormImageSelector, { className: "md:mr-80", variant: "avatar", previewSize: "w-90 h-90", showRemoveButton: true, name: "image", diskPrefix: "avatars", label: _jsx(Trans, { message: "Profile image" }), onChange: url => {
                            if (url) {
                                uploadAvatar.mutate({ url });
                            }
                            else {
                                removeAvatar.mutate();
                            }
                        } }) })] }) }));
}

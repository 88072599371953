import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAuth } from '@common/auth/use-auth';
import { useThemeSelector } from '@ui/themes/theme-selector-context';
import { Badge } from '@ui/badge/badge';
import { ButtonBase } from '@ui/buttons/button-base';
import { ArrowDropDownIcon } from '@ui/icons/material/ArrowDropDown';
import { NavbarAuthMenu } from '@common/ui/navigation/navbar/navbar-auth-menu';
import { UserAvatar } from '@common/auth/user-avatar';
export function NavbarAuthUser({ items = [], variant = 'wide', wideAvatarSize = 'w-32 h-32', compactAvatarSize = 'w-26 h-26', }) {
    const { user } = useAuth();
    const { selectedTheme } = useThemeSelector();
    if (!selectedTheme || !user)
        return null;
    const hasUnreadNotif = !!user.unread_notifications_count;
    const avatar = (_jsx(UserAvatar, { user: user, circle: true, fallback: "initials", size: variant === 'wide' ? wideAvatarSize : compactAvatarSize }));
    const compactButton = (_jsxs(ButtonBase, { "aria-label": "toggle authentication menu", display: "block", children: [avatar, hasUnreadNotif ? (_jsx(Badge, { children: user.unread_notifications_count })) : undefined] }));
    const wideButton = (_jsxs(ButtonBase, { className: "flex items-center", children: [avatar, _jsx("span", { className: "ml-12 mr-2 block max-w-124 overflow-x-hidden overflow-ellipsis text-sm", children: user.name }), _jsx(ArrowDropDownIcon, { className: "block icon-sm" })] }));
    return (_jsx(NavbarAuthMenu, { items: items, children: _jsx("span", { children: variant === 'wide' ? wideButton : compactButton }) }));
}

import { useAuth } from '../auth/use-auth';
import { useSettings } from '@ui/settings/use-settings';
import dot from 'dot-object';
import { useMemo } from 'react';
export function useCustomMenu(menuOrPosition) {
    const settings = useSettings();
    const { user, hasPermission } = useAuth();
    return useMemo(() => {
        if (!menuOrPosition) {
            return null;
        }
        const menu = typeof menuOrPosition === 'string'
            ? settings.menus?.find(s => s.positions?.includes(menuOrPosition))
            : menuOrPosition;
        const menuItems = [];
        if (menu) {
            for (const item of menu.items) {
                const hasRoles = (item.roles || []).every(a => user?.roles.find(b => b.id === a));
                const hasPermissions = (item.permissions || []).every(a => hasPermission(a));
                const hasSettings = !item.settings ||
                    Object.entries(item.settings).every(([key, value]) => {
                        return dot.pick(key, settings) == value;
                    });
                if (!item.action ||
                    !hasRoles ||
                    !hasPermissions ||
                    !hasSettings ||
                    !subscriptionStatusMatches(item, user)) {
                    continue;
                }
                if (user?.id) {
                    item.action = item.action.replace(/{currentUser}/g, `${user.id}`);
                }
                menuItems.push(item);
            }
            menu.items = menuItems;
        }
        return menu;
    }, [hasPermission, settings, menuOrPosition, user]);
}
function subscriptionStatusMatches(item, user) {
    if (!item.subscriptionStatus) {
        return true;
    }
    const hasActiveSubscription = !!user?.subscriptions?.find(sub => sub.active);
    if (item.subscriptionStatus === 'subscribed') {
        return hasActiveSubscription;
    }
    return !hasActiveSubscription;
}

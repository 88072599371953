import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useForm } from 'react-hook-form';
import { useId } from 'react';
import { Form } from '@ui/forms/form';
import { AccountSettingsPanel } from '@common/auth/ui/account-settings/account-settings-panel';
import { useUpdateAccountDetails } from '@common/auth/ui/account-settings/basic-info-panel/update-account-details';
import { Button } from '@ui/buttons/button';
import { useValueLists } from '@common/http/value-lists';
import { FormSelect } from '@ui/forms/select/select';
import { useChangeLocale } from '@common/locale-switcher/change-locale';
import { AccountSettingsId } from '@common/auth/ui/account-settings/account-settings-sidenav';
import { TimezoneSelect } from '@common/auth/ui/account-settings/timezone-select';
import { Trans } from '@ui/i18n/trans';
import { useTrans } from '@ui/i18n/use-trans';
import { message } from '@ui/i18n/message';
import { Item } from '@ui/forms/listbox/item';
export function LocalizationPanel({ user }) {
    const formId = useId();
    const { trans } = useTrans();
    const form = useForm({
        defaultValues: {
            language: user.language || '',
            country: user.country || '',
            timezone: user.timezone || 'UTC',
        },
    });
    const updateDetails = useUpdateAccountDetails(user.id, form);
    const changeLocale = useChangeLocale();
    const { data } = useValueLists(['timezones', 'countries', 'localizations']);
    const countries = data?.countries || [];
    const localizations = data?.localizations || [];
    const timezones = data?.timezones || {};
    return (_jsx(AccountSettingsPanel, { id: AccountSettingsId.LocationAndLanguage, title: _jsx(Trans, { message: "Date, time and language" }), actions: _jsx(Button, { type: "submit", variant: "flat", color: "primary", form: formId, disabled: updateDetails.isPending || !form.formState.isValid, children: _jsx(Trans, { message: "Save" }) }), children: _jsxs(Form, { form: form, onSubmit: newDetails => {
                updateDetails.mutate(newDetails);
                changeLocale.mutate({ locale: newDetails.language });
            }, id: formId, children: [_jsx(FormSelect, { className: "mb-24", selectionMode: "single", name: "language", label: _jsx(Trans, { message: "Language" }), children: localizations.map(localization => (_jsx(Item, { value: localization.language, children: localization.name }, localization.language))) }), _jsx(FormSelect, { className: "mb-24", selectionMode: "single", name: "country", label: _jsx(Trans, { message: "Country" }), showSearchField: true, searchPlaceholder: trans(message('Search countries')), children: countries.map(country => (_jsx(Item, { value: country.code, children: country.name }, country.code))) }), _jsx(TimezoneSelect, { label: _jsx(Trans, { message: "Timezone" }), name: "timezone", timezones: timezones })] }) }));
}

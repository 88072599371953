import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconButton } from '@ui/buttons/icon-button';
import { NotificationsIcon } from '@ui/icons/material/Notifications';
import { Button } from '@ui/buttons/button';
import { useUserNotifications } from './requests/user-notifications';
import { ProgressCircle } from '@ui/progress/progress-circle';
import { NotificationList } from '../notification-list';
import { DialogTrigger } from '@ui/overlays/dialog/dialog-trigger';
import { Dialog } from '@ui/overlays/dialog/dialog';
import { DialogHeader } from '@ui/overlays/dialog/dialog-header';
import { DialogBody } from '@ui/overlays/dialog/dialog-body';
import { Badge } from '@ui/badge/badge';
import { DoneAllIcon } from '@ui/icons/material/DoneAll';
import { useMarkNotificationsAsRead } from '../requests/use-mark-notifications-as-read';
import { NotificationEmptyStateMessage } from '../empty-state/notification-empty-state-message';
import { SettingsIcon } from '@ui/icons/material/Settings';
import { Link } from 'react-router';
import { useSettings } from '@ui/settings/use-settings';
import { useAuth } from '@common/auth/use-auth';
import { Trans } from '@ui/i18n/trans';
export function NotificationDialogTrigger({ className, }) {
    const { user } = useAuth();
    const hasUnread = !!user?.unread_notifications_count;
    return (_jsxs(DialogTrigger, { type: "popover", children: [_jsx(IconButton, { size: "md", className: className, badge: hasUnread ? (_jsx(Badge, { className: "max-md:hidden", children: user?.unread_notifications_count })) : undefined, children: _jsx(NotificationsIcon, {}) }), _jsx(NotificationsDialog, {})] }));
}
export function NotificationsDialog() {
    const { user } = useAuth();
    const { notif } = useSettings();
    const query = useUserNotifications();
    const markAsRead = useMarkNotificationsAsRead();
    const hasUnread = !!user?.unread_notifications_count;
    const handleMarkAsRead = () => {
        if (!query.data)
            return;
        markAsRead.mutate({
            markAllAsUnread: true,
        });
    };
    return (_jsxs(Dialog, { children: [_jsx(DialogHeader, { showDivider: true, actions: !hasUnread &&
                    notif.subs.integrated && (_jsx(IconButton, { className: "text-muted", size: "sm", elementType: Link, to: "/notifications/settings", target: "_blank", children: _jsx(SettingsIcon, {}) })), rightAdornment: hasUnread && (_jsx(Button, { variant: "text", color: "primary", size: "xs", startIcon: _jsx(DoneAllIcon, {}), onClick: handleMarkAsRead, disabled: markAsRead.isPending, className: "max-md:hidden", children: _jsx(Trans, { message: "Mark all as read" }) })), children: _jsx(Trans, { message: "Notifications" }) }), _jsx(DialogBody, { padding: "p-0", children: _jsx(DialogContent, {}) })] }));
}
function DialogContent() {
    const { data, isLoading } = useUserNotifications();
    if (isLoading) {
        return (_jsx("div", { className: "flex items-center justify-center px-24 py-20", children: _jsx(ProgressCircle, { "aria-label": "Loading notifications...", isIndeterminate: true }) }));
    }
    if (!data?.pagination.data.length) {
        return (_jsx("div", { className: "px-24 py-20", children: _jsx(NotificationEmptyStateMessage, {}) }));
    }
    return (_jsx("div", { children: _jsx(NotificationList, { notifications: data.pagination.data }) }));
}

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Radio } from '@ui/forms/radio-group/radio';
import { Trans } from '@ui/i18n/trans';
import { RadioGroup } from '@ui/forms/radio-group/radio-group';
import { UpsellLabel } from './upsell-label';
export function BillingCycleRadio({ selectedCycle, onChange, products, ...radioGroupProps }) {
    return (_jsxs(RadioGroup, { ...radioGroupProps, children: [_jsxs(Radio, { value: "yearly", checked: selectedCycle === 'yearly', onChange: e => {
                    onChange(e.target.value);
                }, children: [_jsx(Trans, { message: "Annual" }), _jsx(UpsellLabel, { products: products })] }), _jsx(Radio, { value: "monthly", checked: selectedCycle === 'monthly', onChange: e => {
                    onChange(e.target.value);
                }, children: _jsx(Trans, { message: "Monthly" }) })] }));
}

import { jsx as _jsx } from "react/jsx-runtime";
import clsx from 'clsx';
import { forwardRef, } from 'react';
import { Link } from 'react-router';
import { Tooltip } from '@ui/tooltip/tooltip';
import { AvatarPlaceholderIcon } from '@ui/avatar/avatar-placeholder-icon';
import { AvatarInitialsFallback } from '@ui/avatar/avatar-initials-fallback';
export const Avatar = forwardRef(function ({ className, circle, size = 'md', src, link, label, labelForBackground, fallback = 'initials', fallbackColor, lazy = true, ...domProps }, ref) {
    let renderedAvatar = src ? (_jsx("img", { ref: ref, src: src, alt: label, loading: lazy ? 'lazy' : undefined, className: "block h-full w-full object-cover" })) : fallback === 'initials' && label ? (_jsx(AvatarInitialsFallback, { size: size, label: label, labelForBackground: labelForBackground, color: fallbackColor })) : (_jsx("div", { className: "h-full w-full bg-alt dark:bg-chip", children: _jsx(AvatarPlaceholderIcon, { viewBox: "0 0 48 48", className: "h-full w-full text-muted" }) }));
    if (label) {
        renderedAvatar = _jsx(Tooltip, { label: label, children: renderedAvatar });
    }
    const wrapperProps = {
        ...domProps,
        className: clsx(className, 'relative block overflow-hidden select-none flex-shrink-0', getSizeClassName(size), circle ? 'rounded-full' : 'rounded'),
    };
    return link ? (_jsx(Link, { ...wrapperProps, to: link, children: renderedAvatar })) : (_jsx("div", { ...wrapperProps, children: renderedAvatar }));
});
function getSizeClassName(size) {
    switch (size) {
        case 'xs':
            return 'w-18 h-18';
        case 'sm':
            return 'w-24 h-24';
        case 'md':
            return 'w-32 h-32';
        case 'lg':
            return 'w-40 h-40';
        case 'xl':
            return 'w-60 h-60';
        // allow overriding with custom classNames
        default:
            return size;
    }
}

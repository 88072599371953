import { jsx as _jsx } from "react/jsx-runtime";
import { IllustratedMessage } from '@ui/images/illustrated-message';
import { SvgImage } from '@ui/images/svg-image';
import notifySvg from './notify.svg';
import { Trans } from '@ui/i18n/trans';
import { Button } from '@ui/buttons/button';
import { Link } from 'react-router';
import { useSettings } from '@ui/settings/use-settings';
export function NotificationEmptyStateMessage() {
    const { notif } = useSettings();
    return (_jsx(IllustratedMessage, { size: "sm", image: _jsx(SvgImage, { src: notifySvg }), title: _jsx(Trans, { message: "Hang tight!" }), description: _jsx(Trans, { message: "Notifications will start showing up here soon." }), action: notif.subs.integrated && (_jsx(Button, { elementType: Link, variant: "outline", to: "/notifications/settings", size: "xs", color: "primary", children: _jsx(Trans, { message: "Notification settings" }) })) }));
}

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Dialog } from '@ui/overlays/dialog/dialog';
import { useDialogContext } from '@ui/overlays/dialog/dialog-context';
import { useConfirmPassword, } from '@common/auth/ui/confirm-password/requests/use-confirm-password';
import { useForm } from 'react-hook-form';
import { DialogHeader } from '@ui/overlays/dialog/dialog-header';
import { Trans } from '@ui/i18n/trans';
import { DialogBody } from '@ui/overlays/dialog/dialog-body';
import { Form } from '@ui/forms/form';
import { FormTextField } from '@ui/forms/input-field/text-field/text-field';
import { DialogFooter } from '@ui/overlays/dialog/dialog-footer';
import { Button } from '@ui/buttons/button';
export function ConfirmPasswordDialog() {
    const { close, formId } = useDialogContext();
    const form = useForm();
    const confirmPassword = useConfirmPassword(form);
    return (_jsxs(Dialog, { children: [_jsx(DialogHeader, { children: _jsx(Trans, { message: "Confirm password" }) }), _jsxs(DialogBody, { children: [_jsx("p", { className: "mb-16 text-sm", children: _jsx(Trans, { message: "For your security, please confirm your password to continue." }) }), _jsx(Form, { id: formId, form: form, onSubmit: values => confirmPassword.mutate(values, {
                            onSuccess: () => close(values.password),
                        }), children: _jsx(FormTextField, { name: "password", label: _jsx(Trans, { message: "Password" }), type: "password", required: true, autoFocus: true }) })] }), _jsxs(DialogFooter, { children: [_jsx(Button, { onClick: () => close(), children: _jsx(Trans, { message: "Cancel" }) }), _jsx(Button, { type: "submit", variant: "flat", color: "primary", form: formId, disabled: confirmPassword.isPending, children: _jsx(Trans, { message: "Confirm" }) })] })] }));
}

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, StrictMode, useEffect, useRef, useState } from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import { domAnimation, LazyMotion } from 'framer-motion';
import { queryClient } from '@common/http/query-client';
import { SiteConfigContext } from '@common/core/settings/site-config-context';
import { SiteConfig } from '@app/site-config';
import deepMerge from 'deepmerge';
import { BaseSiteConfig } from '@common/core/settings/base-site-config';
import { ThemeProvider } from '@common/core/theme-provider';
import { AppearanceListener } from '@common/admin/appearance/commands/appearance-listener';
import { CookieNotice } from '@common/ui/cookie-notice/cookie-notice';
import { ToastContainer } from '@ui/toast/toast-container';
import { DialogStoreOutlet } from '@ui/overlays/store/dialog-store-outlet';
import { useSettings } from '@ui/settings/use-settings';
import { useAuth } from '@common/auth/use-auth';
import { verifyEmailRouter } from '@common/auth/ui/email-verification-page/email-verification-page';
import { Outlet, RouterProvider, useNavigation, useRouteError, } from 'react-router';
import { TopProgressBar } from '@ui/progress/top-progress-bar';
import { NotFoundPage } from '@common/ui/not-found-page/not-found-page';
import { PageErrorMessage } from '@common/errors/page-error-message';
import { userSuspendedRouter } from '@common/auth/ui/user-suspended-page/user-suspended-page';
const mergedConfig = deepMerge(BaseSiteConfig, SiteConfig);
export function CommonProvider({ router }) {
    return (_jsx(StrictMode, { children: _jsx(QueryClientProvider, { client: queryClient, children: _jsx(LazyMotion, { features: domAnimation, children: _jsx(SiteConfigContext.Provider, { value: mergedConfig, children: _jsx(ThemeProvider, { children: _jsx(CommonRouter, { router: router }) }) }) }) }) }));
}
function CommonRouter({ router }) {
    const { require_email_confirmation } = useSettings();
    const { user } = useAuth();
    if (user != null && require_email_confirmation && !user.email_verified_at) {
        return _jsx(RouterProvider, { router: verifyEmailRouter });
    }
    if (user != null && user.banned_at) {
        return _jsx(RouterProvider, { router: userSuspendedRouter });
    }
    return _jsx(RouterProvider, { router: router });
}
export function RootRoute() {
    return (_jsxs(Fragment, { children: [_jsx(GlobalTopLoadingBar, {}), _jsx(Outlet, {}), _jsx(AppearanceListener, {}), _jsx(CookieNotice, {}), _jsx(ToastContainer, {}), _jsx(DialogStoreOutlet, {})] }));
}
export function RootErrorElement() {
    const error = useRouteError();
    if (error?.status === 404) {
        return _jsx(NotFoundPage, {});
    }
    return _jsx(PageErrorMessage, {});
}
export function GlobalTopLoadingBar() {
    const [bar] = useState(() => new TopProgressBar());
    const { state } = useNavigation();
    const timeoutRef = useRef(null);
    useEffect(() => {
        // react router will always set loading to true when "lazy" is set on route, even if that router is already loaded, this will result in loading bar showing for a few milliseconds
        if (state === 'loading') {
            timeoutRef.current = setTimeout(() => {
                bar.show();
            }, 50);
        }
        else {
            clearTimeout(timeoutRef.current);
            bar.hide();
        }
        return () => {
            if (state !== 'loading') {
                clearTimeout(timeoutRef.current);
                bar.hide();
            }
        };
    }, [state, bar]);
    return null;
}

import { jsx as _jsx } from "react/jsx-runtime";
import { RegisterPage } from './ui/register-page';
import { AuthRoute } from './guards/auth-route';
import { AccountSettingsPage } from './ui/account-settings/account-settings-page';
import { GuestRoute } from './guards/guest-route';
import { ForgotPasswordPage } from './ui/forgot-password-page';
import { ResetPasswordPage } from './ui/reset-password-page';
import { LoginPageWrapper } from '@common/auth/ui/login-page-wrapper';
export const authRoutes = [
    {
        path: '/register',
        element: (_jsx(GuestRoute, { children: _jsx(RegisterPage, {}) })),
    },
    {
        path: '/login',
        element: (_jsx(GuestRoute, { children: _jsx(LoginPageWrapper, {}) })),
    },
    {
        path: '/workspace/join/register',
        element: (_jsx(GuestRoute, { children: _jsx(RegisterPage, {}) })),
    },
    {
        path: '/workspace/join/login',
        element: (_jsx(GuestRoute, { children: _jsx(LoginPageWrapper, {}) })),
    },
    {
        path: '/forgot-password',
        element: (_jsx(GuestRoute, { children: _jsx(ForgotPasswordPage, {}) })),
    },
    {
        path: '/password/reset/:token',
        element: (_jsx(GuestRoute, { children: _jsx(ResetPasswordPage, {}) })),
    },
    {
        path: '/account-settings',
        element: (_jsx(AuthRoute, { children: _jsx(AccountSettingsPage, {}) })),
    },
];

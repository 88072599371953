import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Trans } from '@ui/i18n/trans';
import clsx from 'clsx';
import { FormattedCurrency } from '@ui/i18n/formatted-currency';
export function FormattedPrice({ price, variant = 'slash', className, priceClassName, periodClassName, }) {
    if (!price)
        return null;
    const translatedInterval = _jsx(Trans, { message: price.interval });
    return (_jsxs("div", { className: clsx('flex items-center gap-6', className), children: [_jsx("div", { className: priceClassName, children: _jsx(FormattedCurrency, { value: price.amount / (price.interval_count ?? 1), currency: price.currency }) }), variant === 'slash' ? (_jsxs("div", { className: periodClassName, children: [" / ", translatedInterval] })) : (_jsxs("div", { className: periodClassName, children: [_jsx(Trans, { message: "per" }), " ", _jsx("br", {}), " ", translatedInterval] }))] }));
}

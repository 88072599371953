import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AccountSettingsPanel } from '../account-settings-panel';
import { Button } from '@ui/buttons/button';
import { DialogTrigger } from '@ui/overlays/dialog/dialog-trigger';
import { ConfirmationDialog } from '@ui/overlays/dialog/confirmation-dialog';
import { useDeleteAccount } from './delete-account';
import { Trans } from '@ui/i18n/trans';
import { AccountSettingsId } from '@common/auth/ui/account-settings/account-settings-sidenav';
import { useState } from 'react';
import { usePasswordConfirmedAction } from '@common/auth/ui/confirm-password/use-password-confirmed-action';
export function DangerZonePanel() {
    const deleteAccount = useDeleteAccount();
    const { withConfirmedPassword, isLoading: confirmingPassword } = usePasswordConfirmedAction();
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    return (_jsxs(AccountSettingsPanel, { id: AccountSettingsId.DeleteAccount, title: _jsx(Trans, { message: "Danger zone" }), children: [_jsx(DialogTrigger, { type: "modal", isOpen: confirmDialogOpen, onOpenChange: setConfirmDialogOpen, onClose: isConfirmed => {
                    if (isConfirmed) {
                        deleteAccount.mutate();
                    }
                }, children: _jsx(ConfirmationDialog, { isDanger: true, title: _jsx(Trans, { message: "Delete account?" }), body: _jsx(Trans, { message: "Your account will be deleted immediately and permanently. Once deleted, accounts can not be restored." }), confirm: _jsx(Trans, { message: "Delete" }) }) }), _jsx(Button, { variant: "flat", color: "danger", disabled: confirmingPassword || deleteAccount.isPending, onClick: () => {
                    withConfirmedPassword(() => {
                        setConfirmDialogOpen(true);
                    });
                }, children: _jsx(Trans, { message: "Delete account" }) })] }));
}

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { Navbar } from '@common/ui/navigation/navbar/navbar';
import { Button } from '@ui/buttons/button';
import { IconButton } from '@ui/buttons/icon-button';
import { KeyboardArrowDownIcon } from '@ui/icons/material/KeyboardArrowDown';
import { Footer } from '@common/ui/footer/footer';
import { Trans } from '@ui/i18n/trans';
import { AdHost } from '@common/admin/ads/ad-host';
import { Link } from 'react-router';
import { createSvgIconFromTree } from '@ui/icons/create-svg-icon';
import { Fragment } from 'react';
import { DefaultMetaTags } from '@common/seo/default-meta-tags';
import { useTrans } from '@ui/i18n/use-trans';
import { useSettings } from '@ui/settings/use-settings';
import { MixedImage } from '@ui/images/mixed-image';
export function LandingPage() {
    const settings = useSettings();
    const homepage = settings.homepage;
    return (_jsxs(Fragment, { children: [_jsx(DefaultMetaTags, {}), _jsxs("div", { children: [_jsx(HeroHeader, { content: homepage.appearance }), _jsx(AdHost, { slot: "landing-top", className: "mb-14 px-14 md:mb-60" }), _jsx(PrimaryFeatures, { content: homepage.appearance }), _jsx("div", { className: "my-40 h-1 bg-divider" }), _jsx(SecondaryFeatures, { content: homepage.appearance }), _jsx(BottomCta, { content: homepage.appearance }), _jsx(Footer, { className: "landing-container" })] })] }));
}
function HeroHeader({ content: { headerTitle, headerSubtitle, headerImage, headerImageOpacity, actions, headerOverlayColor1, headerOverlayColor2, }, }) {
    const { trans } = useTrans();
    let overlayBackground = undefined;
    if (headerOverlayColor1 && headerOverlayColor2) {
        overlayBackground = `linear-gradient(45deg, ${headerOverlayColor1} 0%, ${headerOverlayColor2} 100%)`;
    }
    else if (headerOverlayColor1) {
        overlayBackground = headerOverlayColor1;
    }
    else if (headerOverlayColor2) {
        overlayBackground = headerOverlayColor2;
    }
    return (_jsxs("header", { className: "relative mb-14 h-500 md:mb-60 md:h-screen", style: { background: overlayBackground }, children: [_jsx("div", { "data-testid": "headerImage", className: "absolute inset-0 z-10 bg-cover bg-1/2 bg-no-repeat md:bg-fixed", style: {
                    backgroundImage: `url(${headerImage})`,
                    opacity: headerImageOpacity,
                } }), _jsxs("div", { className: "relative z-20 flex h-full flex-col", children: [_jsx(Navbar, { color: "transparent", className: "flex-shrink-0", menuPosition: "homepage-navbar" }), _jsxs("div", { className: "mx-auto flex max-w-850 flex-auto flex-col items-center justify-center px-14 text-center text-white", children: [headerTitle && (_jsx("h1", { className: "text-4xl font-medium md:text-5xl", "data-testid": "headerTitle", children: _jsx(Trans, { message: headerTitle }) })), headerSubtitle && (_jsx("div", { className: "mt-30 text-lg md:mt-10 md:text-xl", "data-testid": "headerSubtitle", children: _jsx(Trans, { message: headerSubtitle }) })), _jsxs("div", { className: "mt-30 flex min-h-50 gap-20", children: [_jsx(CtaButton, { item: actions?.cta1, variant: "raised", color: "primary", size: "lg", radius: "rounded-full", "data-testid": "cta1", className: "min-w-180" }), _jsx(CtaButton, { item: actions?.cta2, variant: "text", color: "paper", size: "lg", radius: "rounded-full", "data-testid": "cta2" })] })] })] }), _jsx(IconButton, { size: "lg", className: "absolute bottom-5 left-0 right-0 z-30 mx-auto text-white max-md:hidden", elementType: "a", "aria-label": trans({ message: 'View features' }), href: "#primary-features", children: _jsx(KeyboardArrowDownIcon, {}) })] }));
}
function CtaButton({ item, ...buttonProps }) {
    if (!item?.label)
        return null;
    const Icon = item.icon ? createSvgIconFromTree(item.icon) : undefined;
    return (_jsx(Button, { elementType: item.type === 'route' ? Link : 'a', href: item.action, to: item.action, startIcon: Icon ? _jsx(Icon, {}) : undefined, ...buttonProps, children: _jsx(Trans, { message: item.label }) }));
}
function PrimaryFeatures({ content }) {
    return (_jsx("div", { className: "landing-container items-stretch gap-26 md:flex", id: "primary-features", children: content?.primaryFeatures?.map((feature, index) => (_jsxs("div", { className: "mb-14 flex-1 rounded-2xl px-24 py-36 text-center shadow-[0_10px_30px_rgba(0,0,0,0.08)] md:mb-0", "data-testid": `primary-root-${index}`, children: [_jsx(MixedImage, { className: "mx-auto mb-30 h-128", "data-testid": `primary-image-${index}`, src: feature.image }), _jsx("h2", { className: "my-16 text-lg font-medium", "data-testid": `primary-title-${index}`, children: _jsx(Trans, { message: feature.title }) }), _jsx("div", { className: "text-md text-[0.938rem]", "data-testid": `primary-subtitle-${index}`, children: _jsx(Trans, { message: feature.subtitle }) })] }, index))) }));
}
function SecondaryFeatures({ content }) {
    return (_jsx("div", { className: "landing-container", children: content?.secondaryFeatures?.map((feature, index) => {
            const isEven = index % 2 === 0;
            return (_jsxs("div", { "data-testid": `secondary-root-${index}`, className: clsx('mb-14 py-16 md:mb-80 md:flex', isEven && 'flex-row-reverse'), children: [_jsx("img", { src: feature.image, className: "mr-auto w-580 max-w-full rounded-lg shadow-[0_10px_30px_rgba(0,0,0,0.08)]", "data-testid": `secondary-image-${index}`, alt: "" }), _jsxs("div", { className: "ml-30 mr-auto max-w-350 pt-30", children: [_jsx("small", { className: "mb-16 text-xs font-medium uppercase tracking-widest text-muted", "data-testid": `secondary-subtitle-${index}`, children: _jsx(Trans, { message: feature.subtitle }) }), _jsx("h3", { className: "py-16 text-3xl", "data-testid": `secondary-title-${index}`, children: _jsx(Trans, { message: feature.title }) }), _jsx("div", { className: "h-2 w-50 bg-black/90" }), _jsx("div", { className: "my-20 text-[0.938rem]", "data-testid": `secondary-description-${index}`, children: _jsx(Trans, { message: feature.description }) })] })] }, index));
        }) }));
}
function BottomCta({ content }) {
    return (_jsxs("div", { className: "relative bg-[#2B2B2B] bg-no-repeat py-70 text-center text-on-primary md:bg-fixed", style: { backgroundImage: `url("${content.footerImage}")` }, "data-testid": "footerImage", children: [_jsx("h2", { className: "mx-auto max-w-620 text-3xl font-normal", "data-testid": "footerTitle", children: _jsx(Trans, { message: content.footerTitle }) }), content.footerSubtitle && (_jsx("p", { className: "mx-auto mt-50 max-w-620 text-2xl font-normal", "data-testid": "footerSubtitle", children: _jsx(Trans, { message: content.footerSubtitle }) })), _jsx(CtaButton, { item: content.actions?.cta3, size: "lg", variant: "outline", color: "paper", className: "mt-50 block", "data-testid": "cta3" })] }));
}

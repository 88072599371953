import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AnimatePresence, m } from 'framer-motion';
import { opacityAnimation } from '@ui/animation/opacity-animation';
import { useTwoFactorQrCode } from '@common/auth/ui/two-factor/requests/use-two-factor-qr-code';
import { useForm } from 'react-hook-form';
import { useConfirmTwoFactor, } from '@common/auth/ui/two-factor/requests/use-confirm-two-factor';
import { TwoFactorStepperLayout } from '@common/auth/ui/two-factor/stepper/two-factor-stepper-layout';
import { Trans } from '@ui/i18n/trans';
import { Skeleton } from '@ui/skeleton/skeleton';
import { Form } from '@ui/forms/form';
import { queryClient } from '@common/http/query-client';
import { FormTextField } from '@ui/forms/input-field/text-field/text-field';
import { useDisableTwoFactor } from '@common/auth/ui/two-factor/requests/use-disable-two-factor';
import { usePasswordConfirmedAction } from '@common/auth/ui/confirm-password/use-password-confirmed-action';
import { Button } from '@ui/buttons/button';
export function TwoFactorConfirmationStep(props) {
    const { data } = useTwoFactorQrCode();
    return (_jsxs(TwoFactorStepperLayout, { title: _jsx(Trans, { message: "Finish enabling two factor authentication." }), description: _jsx(Trans, { message: "To finish enabling two factor authentication, scan the following QR code using your phone's authenticator application or enter the setup key and provide the generated OTP code." }), children: [_jsx(AnimatePresence, { initial: false, children: !data ? (_jsx(QrCodeLayout, { animationKey: "svg-skeleton", svg: _jsx(Skeleton, { variant: "rect", size: "w-full h-full" }), secret: _jsx(Skeleton, { variant: "text", className: "max-w-224" }) })) : (_jsx(QrCodeLayout, { animationKey: "real-svg", svg: _jsx("div", { dangerouslySetInnerHTML: { __html: data.svg } }), secret: _jsx(Trans, { message: "Setup key: :key", values: { key: data.secret } }) })) }), _jsx(CodeForm, { ...props })] }));
}
function CodeForm({ onCancel, onConfirmed }) {
    const form = useForm();
    const confirmTwoFactor = useConfirmTwoFactor(form);
    const disableTwoFactor = useDisableTwoFactor();
    const { withConfirmedPassword, isLoading: confirmPasswordIsLoading } = usePasswordConfirmedAction();
    const isLoading = confirmTwoFactor.isPending ||
        disableTwoFactor.isPending ||
        confirmPasswordIsLoading;
    return (_jsxs(Form, { form: form, onSubmit: values => withConfirmedPassword(() => {
            confirmTwoFactor.mutate(values, {
                onSuccess: () => {
                    queryClient.invalidateQueries({ queryKey: ['users'] });
                    onConfirmed();
                },
            });
        }), children: [_jsx(FormTextField, { required: true, name: "code", label: _jsx(Trans, { message: "Code" }), autoFocus: true }), _jsxs("div", { className: "mt-24 flex items-center gap-12", children: [_jsx(Button, { type: "button", variant: "outline", disabled: isLoading, onClick: () => {
                            withConfirmedPassword(() => {
                                disableTwoFactor.mutate(undefined, { onSuccess: onCancel });
                            });
                        }, children: _jsx(Trans, { message: "Cancel" }) }), _jsx(Button, { type: "submit", variant: "flat", color: "primary", disabled: isLoading, children: _jsx(Trans, { message: "Confirm" }) })] })] }));
}
function QrCodeLayout({ animationKey, svg, secret }) {
    return (_jsxs(m.div, { ...opacityAnimation, children: [_jsx("div", { className: "mb-16 h-192 w-192", children: svg }), _jsx("div", { className: "mb-16 text-sm font-medium", children: secret })] }, animationKey));
}

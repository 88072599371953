import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { cloneElement, Fragment, useCallback, useId, useRef, } from 'react';
import clsx from 'clsx';
import { Button } from '@ui/buttons/button';
import { Trans } from '@ui/i18n/trans';
import { useActiveUpload } from '@common/uploads/uploader/use-active-upload';
import { useController } from 'react-hook-form';
import { mergeProps } from '@react-aria/utils';
import { ProgressBar } from '@ui/progress/progress-bar';
import { Disk } from '../uploader/backend-metadata';
import { toast } from '@ui/toast/toast';
import { Field } from '@ui/forms/input-field/field';
import { getInputFieldClassNames, } from '@ui/forms/input-field/get-input-field-class-names';
import { useAutoFocus } from '@ui/focus/use-auto-focus';
import { IconButton } from '@ui/buttons/icon-button';
import { AddAPhotoIcon } from '@ui/icons/material/AddAPhoto';
import { AvatarPlaceholderIcon } from '@ui/avatar/avatar-placeholder-icon';
import { FileInputType } from '@ui/utils/files/file-input-config';
const TwoMB = 2 * 1024 * 1024;
export function ImageSelector({ className, label, description, value, onChange, defaultValue, diskPrefix, showRemoveButton, showEditButtonOnHover = false, invalid, errorMessage, required, autoFocus, variant = 'input', previewSize = 'h-80', placeholderIcon, stretchPreview = false, previewRadius, disabled, onFileSelected, }) {
    const { uploadFile, entry, uploadStatus, deleteEntry, isDeletingEntry, percentage, } = useActiveUpload();
    const inputRef = useRef(null);
    useAutoFocus({ autoFocus }, inputRef);
    const fieldId = useId();
    const labelId = label ? `${fieldId}-label` : undefined;
    const descriptionId = description ? `${fieldId}-description` : undefined;
    const imageUrl = value || entry?.url;
    const uploadOptions = {
        showToastOnRestrictionFail: true,
        restrictions: {
            allowedFileTypes: [FileInputType.image],
            maxFileSize: TwoMB,
        },
        metadata: {
            diskPrefix,
            disk: Disk.public,
        },
        onSuccess: (entry) => {
            onChange?.(entry.url);
        },
        onError: message => {
            if (message) {
                toast.danger(message);
            }
        },
    };
    const inputFieldClassNames = getInputFieldClassNames({
        description,
        descriptionPosition: 'top',
        invalid,
    });
    let VariantElement;
    if (variant === 'avatar') {
        VariantElement = AvatarVariant;
    }
    else if (variant === 'square') {
        VariantElement = SquareVariant;
    }
    else {
        VariantElement = InputVariant;
    }
    const removeButton = showRemoveButton ? (_jsx(Button, { variant: "link", color: "danger", size: "xs", disabled: isDeletingEntry || !imageUrl || disabled, onClick: () => {
            deleteEntry({
                onSuccess: () => onChange?.(''),
            });
        }, children: _jsx(Trans, { message: "Remove image" }) })) : null;
    const useDefaultButton = defaultValue != null && value !== defaultValue ? (_jsx(Button, { variant: "outline", color: "primary", size: "xs", disabled: disabled, onClick: () => {
            onChange?.(defaultValue);
        }, children: _jsx(Trans, { message: "Use default" }) })) : null;
    const handleUpload = useCallback(() => {
        inputRef.current?.click();
    }, []);
    return (_jsxs("div", { className: clsx('text-sm', className), children: [label && (_jsx("div", { id: labelId, className: inputFieldClassNames.label, children: label })), description && (_jsx("div", { className: inputFieldClassNames.description, children: description })), _jsx("div", { "aria-labelledby": labelId, "aria-describedby": descriptionId, children: _jsxs(Field, { fieldClassNames: inputFieldClassNames, errorMessage: errorMessage, invalid: invalid, children: [_jsx(VariantElement, { inputFieldClassNames: inputFieldClassNames, placeholderIcon: placeholderIcon, previewSize: previewSize, isLoading: uploadStatus === 'inProgress', imageUrl: imageUrl, removeButton: removeButton, useDefaultButton: useDefaultButton, showEditButtonOnHover: showEditButtonOnHover, stretchPreview: stretchPreview, previewRadius: previewRadius, handleUpload: handleUpload, disabled: disabled, children: _jsx("input", { ref: inputRef, "aria-labelledby": labelId, "aria-describedby": descriptionId, 
                                // if file is already uploaded (from form or via props) set
                                // required to false, otherwise farm validation will always fail
                                required: imageUrl ? false : required, accept: FileInputType.image, type: "file", disabled: uploadStatus === 'inProgress', className: "sr-only", onChange: e => {
                                    if (e.target.files?.length) {
                                        uploadFile(e.target.files[0], uploadOptions);
                                        onFileSelected?.(e.target.files[0]);
                                    }
                                } }) }), uploadStatus === 'inProgress' && (_jsx(ProgressBar, { className: "absolute left-0 right-0 top-0", size: "xs", value: percentage }))] }) })] }));
}
function InputVariant({ children, inputFieldClassNames, imageUrl, previewSize, stretchPreview, isLoading, handleUpload, removeButton, useDefaultButton, disabled, }) {
    if (imageUrl) {
        return (_jsxs(Fragment, { children: [_jsxs("div", { className: `${previewSize} relative mb-10 overflow-hidden rounded border bg-fg-base/8 p-6`, children: [_jsx("img", { className: clsx('mx-auto h-full rounded', stretchPreview ? 'object-cover' : 'object-contain'), onClick: () => handleUpload(), src: imageUrl, alt: "" }), children] }), _jsx(Button, { onClick: () => handleUpload(), disabled: isLoading || disabled, className: "mr-10", variant: "outline", color: "primary", size: "xs", children: _jsx(Trans, { message: "Replace" }) }), removeButton && cloneElement(removeButton, { variant: 'outline' }), useDefaultButton &&
                    cloneElement(useDefaultButton, { variant: 'outline' })] }));
    }
    return cloneElement(children, {
        className: clsx(inputFieldClassNames.input, 'py-8', 'file:bg-primary file:text-on-primary file:border-none file:rounded file:text-sm file:font-semibold file:px-10 file:h-24 file:mr-10'),
    });
}
function SquareVariant({ children, placeholderIcon, previewSize, imageUrl, stretchPreview, handleUpload, removeButton, useDefaultButton, previewRadius = 'rounded', showEditButtonOnHover = false, disabled, }) {
    return (_jsxs("div", { children: [_jsxs("div", { className: clsx(previewSize, previewRadius, !imageUrl && 'border', 'group z-20 flex flex-col items-center justify-center gap-14 bg-fg-base/8 bg-center bg-no-repeat', stretchPreview ? 'bg-cover' : 'bg-contain p-6'), style: imageUrl ? { backgroundImage: `url(${imageUrl})` } : undefined, onClick: () => handleUpload(), children: [placeholderIcon &&
                        !imageUrl &&
                        cloneElement(placeholderIcon, { size: 'lg' }), _jsx(Button, { variant: "raised", color: "white", size: "xs", className: clsx(showEditButtonOnHover && 'invisible group-hover:visible'), disabled: disabled, children: imageUrl ? (_jsx(Trans, { message: "Replace image" })) : (_jsx(Trans, { message: "Upload image" })) })] }), children, (removeButton || useDefaultButton) && (_jsxs("div", { className: "mt-8", children: [removeButton && cloneElement(removeButton, { variant: 'link' }), useDefaultButton &&
                        cloneElement(useDefaultButton, { variant: 'link' })] }))] }));
}
function AvatarVariant({ children, placeholderIcon, previewSize, isLoading, imageUrl, removeButton, useDefaultButton, handleUpload, previewRadius = 'rounded-full', disabled, }) {
    if (!placeholderIcon) {
        placeholderIcon = (_jsx(AvatarPlaceholderIcon, { viewBox: "0 0 48 48", className: clsx('h-full w-full bg-primary-light/40 text-primary/40', previewRadius) }));
    }
    return (_jsxs("div", { children: [_jsxs("div", { className: clsx('relative', previewSize), onClick: () => handleUpload(), children: [imageUrl ? (_jsx("img", { src: imageUrl, className: clsx('h-full w-full object-cover', previewRadius), alt: "" })) : (placeholderIcon), _jsx("div", { className: "absolute -bottom-6 -right-6 rounded-full bg-paper shadow-xl", children: _jsx(IconButton, { disabled: isLoading || disabled, type: "button", variant: "outline", size: "sm", color: "primary", radius: "rounded-full", children: _jsx(AddAPhotoIcon, {}) }) })] }), children, (removeButton || useDefaultButton) && (_jsxs("div", { className: "mt-14", children: [removeButton && cloneElement(removeButton, { variant: 'link' }), useDefaultButton &&
                        cloneElement(useDefaultButton, { variant: 'link' })] }))] }));
}
export function FormImageSelector(props) {
    const { field: { onChange, value = null }, fieldState: { error }, } = useController({
        name: props.name,
    });
    const formProps = {
        onChange,
        value,
        invalid: error != null,
        errorMessage: error ? _jsx(Trans, { message: "Please select an image." }) : null,
    };
    return _jsx(ImageSelector, { ...mergeProps(formProps, props) });
}

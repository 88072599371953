import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AnimatePresence, m } from 'framer-motion';
import { Fragment } from 'react';
import { opacityAnimation } from '@ui/animation/opacity-animation';
import { Skeleton } from '@ui/skeleton/skeleton';
import { useProducts } from '@common/billing/pricing-table/use-products';
import { findBestPrice, } from '@common/billing/pricing-table/find-best-price';
import { useAuth } from '@common/auth/use-auth';
import clsx from 'clsx';
import { Chip } from '@ui/forms/input-field/chip-field/chip';
import { Trans } from '@ui/i18n/trans';
import { FormattedPrice } from '@common/billing/formatted-price';
import { Button } from '@ui/buttons/button';
import { Link } from 'react-router';
import { setInLocalStorage } from '@ui/utils/hooks/local-storage';
import { ProductFeatureList } from '@common/billing/pricing-table/product-feature-list';
export function PricingTable({ selectedCycle, className, productLoader, }) {
    const query = useProducts(productLoader);
    return (_jsx("div", { className: clsx('flex flex-col items-stretch gap-24 overflow-x-auto overflow-y-visible pb-20 md:flex-row md:justify-center', className), children: _jsx(AnimatePresence, { initial: false, mode: "wait", children: query.data ? (_jsx(PlanList, { plans: query.data.products, selectedPeriod: selectedCycle }, "plan-list")) : (_jsx(SkeletonLoader, {}, "skeleton-loader")) }) }));
}
function PlanList({ plans, selectedPeriod }) {
    const { isLoggedIn, isSubscribed } = useAuth();
    const filteredPlans = plans.filter(plan => !plan.hidden);
    return (_jsx(Fragment, { children: filteredPlans.map((plan, index) => {
            const isFirst = index === 0;
            const isLast = index === filteredPlans.length - 1;
            const price = findBestPrice(selectedPeriod, plan.prices);
            let upgradeRoute;
            if (!isLoggedIn) {
                upgradeRoute = `/register?redirectFrom=pricing`;
            }
            if (isSubscribed) {
                upgradeRoute = `/change-plan/${plan.id}/${price?.id}/confirm`;
            }
            if (isLoggedIn && !plan.free) {
                upgradeRoute = `/checkout/${plan.id}/${price?.id}`;
            }
            return (_jsxs(m.div, { ...opacityAnimation, className: clsx('w-full rounded-panel border bg px-28 py-28 shadow-lg md:min-w-240 md:max-w-350', isFirst && 'ml-auto', isLast && 'mr-auto'), children: [_jsxs("div", { className: "mb-32", children: [_jsx(Chip, { radius: "rounded", size: "sm", className: clsx('mb-20 w-min', !plan.recommended && 'invisible'), children: _jsx(Trans, { message: "Most popular" }) }), _jsx("div", { className: "mb-12 text-xl font-semibold", children: _jsx(Trans, { message: plan.name }) }), _jsx("div", { className: "text-sm text-muted", children: _jsx(Trans, { message: plan.description }) })] }), _jsxs("div", { children: [price ? (_jsx(FormattedPrice, { priceClassName: "font-bold text-4xl", periodClassName: "text-muted text-xs", variant: "separateLine", price: price })) : (_jsx("div", { className: "text-4xl font-bold", children: _jsx(Trans, { message: "Free" }) })), _jsx("div", { className: "mt-60", children: _jsx(Button, { variant: plan.recommended ? 'flat' : 'outline', color: "primary", className: "w-full", size: "md", elementType: upgradeRoute ? Link : undefined, disabled: !upgradeRoute, onClick: () => {
                                        if (isLoggedIn || !price || !plan)
                                            return;
                                        setInLocalStorage('be.onboarding.selected', {
                                            productId: plan.id,
                                            priceId: price.id,
                                        });
                                    }, to: upgradeRoute, children: plan.free ? (_jsx(Trans, { message: "Get started" })) : (_jsx(Trans, { message: "Upgrade" })) }) }), _jsx(ProductFeatureList, { product: plan })] })] }, plan.id));
        }) }));
}
function SkeletonLoader() {
    return (_jsxs(Fragment, { children: [_jsx(PlanSkeleton, {}, "skeleton-1"), _jsx(PlanSkeleton, {}, "skeleton-2"), _jsx(PlanSkeleton, {}, "skeleton-3")] }));
}
function PlanSkeleton() {
    return (_jsxs(m.div, { ...opacityAnimation, className: "w-full rounded-lg border px-28 py-90 shadow-lg md:max-w-350", children: [_jsx(Skeleton, { className: "my-10" }), _jsx(Skeleton, { className: "mb-40" }), _jsx(Skeleton, { className: "mb-40 h-30" }), _jsx(Skeleton, { className: "mb-40 h-40" }), _jsx(Skeleton, { className: "mb-20" }), _jsx(Skeleton, {}), _jsx(Skeleton, {})] }));
}

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useDisableTwoFactor } from '@common/auth/ui/two-factor/requests/use-disable-two-factor';
import { useRegenerateTwoFactorCodes } from '@common/auth/ui/two-factor/requests/use-regenerate-two-factor-codes';
import { Fragment } from 'react';
import { queryClient } from '@common/http/query-client';
import { Trans } from '@ui/i18n/trans';
import { TwoFactorStepperLayout } from '@common/auth/ui/two-factor/stepper/two-factor-stepper-layout';
import { toast } from '@ui/toast/toast';
import { message } from '@ui/i18n/message';
import { usePasswordConfirmedAction } from '@common/auth/ui/confirm-password/use-password-confirmed-action';
import { Button } from '@ui/buttons/button';
export function TwoFactorEnabledStep({ user, onDisabled }) {
    const disableTwoFactor = useDisableTwoFactor();
    const regenerateCodes = useRegenerateTwoFactorCodes();
    const { withConfirmedPassword, isLoading: confirmPasswordIsLoading } = usePasswordConfirmedAction();
    const isLoading = disableTwoFactor.isPending ||
        regenerateCodes.isPending ||
        confirmPasswordIsLoading;
    const actions = (_jsxs(Fragment, { children: [_jsx(Button, { type: "button", onClick: () => withConfirmedPassword(() => {
                    regenerateCodes.mutate(undefined, {
                        onSuccess: () => {
                            queryClient.invalidateQueries({ queryKey: ['users'] });
                        },
                    });
                }), variant: "outline", disabled: isLoading, className: "mr-12", children: _jsx(Trans, { message: "Regenerate recovery codes" }) }), _jsx(Button, { type: "submit", variant: "flat", color: "danger", disabled: isLoading, onClick: () => {
                    withConfirmedPassword(() => {
                        disableTwoFactor.mutate(undefined, {
                            onSuccess: () => {
                                toast(message('Two factor authentication has been disabled.'));
                                onDisabled();
                            },
                        });
                    });
                }, children: _jsx(Trans, { message: "Disable" }) })] }));
    return (_jsx(TwoFactorStepperLayout, { title: _jsx(Trans, { message: "You have enabled two factor authentication." }), description: _jsx(Trans, { message: "Store these recovery codes in a secure password manager. They can be used to recover access to your account if your two factor authentication device is lost." }), actions: actions, children: _jsx("div", { className: "mb-16 rounded bg-alt p-14 font-mono text-sm", children: user.two_factor_recovery_codes?.map(code => (_jsx("div", { className: "mb-4", children: code }, code))) }) }));
}

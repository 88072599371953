import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import { SiteConfigContext } from '@common/core/settings/site-config-context';
import { useLogout } from '@common/auth/requests/logout';
import { useCustomMenu } from '@common/menus/use-custom-menu';
import { useSettings } from '@ui/settings/use-settings';
import { useAuth } from '@common/auth/use-auth';
import { useNavigate } from '@common/ui/navigation/use-navigate';
import { useThemeSelector } from '@ui/themes/theme-selector-context';
import { Menu, MenuItem, MenuTrigger } from '@ui/menu/menu-trigger';
import { NotificationsIcon } from '@ui/icons/material/Notifications';
import { Trans } from '@ui/i18n/trans';
import { PaymentsIcon } from '@ui/icons/material/Payments';
import { createSvgIconFromTree } from '@ui/icons/create-svg-icon';
import { AccountCircleIcon } from '@ui/icons/material/AccountCircle';
import { DarkModeIcon } from '@ui/icons/material/DarkMode';
import { LightModeIcon } from '@ui/icons/material/LightMode';
import { ExitToAppIcon } from '@ui/icons/material/ExitToApp';
export function NavbarAuthMenu({ children, items, placement }) {
    const { auth } = useContext(SiteConfigContext);
    const logout = useLogout();
    const menu = useCustomMenu('auth-dropdown');
    const { notifications, themes } = useSettings();
    const { user, isSubscribed } = useAuth();
    const navigate = useNavigate();
    const { selectedTheme, selectTheme } = useThemeSelector();
    if (!selectedTheme || !user)
        return null;
    const hasUnreadNotif = !!user.unread_notifications_count;
    const notifMenuItem = (_jsxs(MenuItem, { className: "md:hidden", value: "notifications", startIcon: _jsx(NotificationsIcon, {}), onSelected: () => {
            navigate('/notifications');
        }, children: [_jsx(Trans, { message: "Notifications" }), hasUnreadNotif ? ` (${user.unread_notifications_count})` : undefined] }));
    const billingMenuItem = (_jsx(MenuItem, { value: "billing", startIcon: _jsx(PaymentsIcon, {}), onSelected: () => {
            navigate('/billing');
        }, children: _jsx(Trans, { message: "Billing" }) }));
    return (_jsxs(MenuTrigger, { placement: placement, children: [children, _jsxs(Menu, { children: [menu &&
                        menu.items.map(item => {
                            const Icon = item.icon && createSvgIconFromTree(item.icon);
                            return (_jsx(MenuItem, { value: item.id, startIcon: Icon && _jsx(Icon, {}), onSelected: () => {
                                    if (item.type === 'link') {
                                        window.open(item.action, '_blank');
                                    }
                                    else {
                                        navigate(item.action);
                                    }
                                }, children: _jsx(Trans, { message: item.label }) }, item.id));
                        }), auth.getUserProfileLink && (_jsx(MenuItem, { value: "profile", startIcon: _jsx(AccountCircleIcon, {}), onSelected: () => {
                            navigate(auth.getUserProfileLink(user));
                        }, children: _jsx(Trans, { message: "Profile page" }) })), items?.map(item => item), notifications?.integrated ? notifMenuItem : undefined, isSubscribed && billingMenuItem, themes?.user_change && !selectedTheme.is_dark && (_jsx(MenuItem, { value: "light", startIcon: _jsx(DarkModeIcon, {}), onSelected: () => {
                            selectTheme('dark');
                        }, children: _jsx(Trans, { message: "Dark mode" }) })), themes?.user_change && selectedTheme.is_dark && (_jsx(MenuItem, { value: "dark", startIcon: _jsx(LightModeIcon, {}), onSelected: () => {
                            selectTheme('light');
                        }, children: _jsx(Trans, { message: "Light mode" }) })), _jsx(MenuItem, { value: "logout", startIcon: _jsx(ExitToAppIcon, {}), onSelected: () => {
                            logout.mutate();
                        }, children: _jsx(Trans, { message: "Log out" }) })] })] }));
}

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AnimatePresence, m } from 'framer-motion';
import clsx from 'clsx';
import { IconButton } from '@ui/buttons/icon-button';
import { CloseIcon } from '@ui/icons/material/Close';
import { MixedText } from '@ui/i18n/mixed-text';
import { Button } from '@ui/buttons/button';
import { toastState, useToastStore } from './toast-store';
import { Link } from 'react-router';
import { ErrorOutlineIcon } from '@ui/icons/material/ErrorOutline';
import { CheckCircleIcon } from '@ui/icons/material/CheckCircle';
import { ProgressCircle } from '@ui/progress/progress-circle';
const initial = { opacity: 0, y: 50, scale: 0.3 };
const animate = { opacity: 1, y: 0, scale: 1 };
const exit = {
    opacity: 0,
    scale: 0.5,
};
export function ToastContainer() {
    const toasts = useToastStore(s => s.toasts);
    return (_jsx("div", { className: "pointer-events-none relative", children: _jsx(AnimatePresence, { initial: false, children: toasts.map(toast => (_jsx("div", { className: clsx('fixed z-toast mx-auto p-20', toast.position === 'bottom-center'
                    ? 'bottom-0 left-0 right-0'
                    : 'bottom-0 right-0'), children: _jsxs(m.div, { initial: toast.disableEnterAnimation ? undefined : initial, animate: toast.disableEnterAnimation ? undefined : animate, exit: toast.disableExitAnimation ? undefined : exit, className: clsx('pointer-events-auto mx-auto flex max-h-100 min-h-50 w-min min-w-288 max-w-500 items-center gap-10 rounded-lg border bg py-6 pl-16 pr-6 text-sm text-main shadow-lg'), onPointerEnter: () => toast.timer?.pause(), onPointerLeave: () => toast.timer?.resume(), role: "alert", "aria-live": toast.type === 'danger' ? 'assertive' : 'polite', children: [toast.type === 'danger' && (_jsx(ErrorOutlineIcon, { className: "flex-shrink-0 text-danger", size: "md" })), toast.type === 'loading' && (_jsx(ProgressCircle, { size: "sm", className: "flex-shrink-0", isIndeterminate: true })), toast.type === 'positive' && (_jsx(CheckCircleIcon, { className: "flex-shrink-0 text-positive", size: "md" })), _jsx("div", { className: "mr-auto w-max overflow-hidden overflow-ellipsis", "data-testid": "toast-message", children: _jsx(MixedText, { value: toast.message }) }), toast.action && (_jsx(Button, { variant: "text", color: "primary", size: "sm", className: "flex-shrink-0", onFocus: () => toast.timer?.pause(), onBlur: () => toast.timer?.resume(), onClick: () => toastState().remove(toast.id), elementType: Link, to: toast.action.action, children: _jsx(MixedText, { value: toast.action.label }) })), toast.type !== 'loading' && (_jsx(IconButton, { onFocus: () => toast.timer?.pause(), onBlur: () => toast.timer?.resume(), type: "button", className: "flex-shrink-0", onClick: () => {
                                toastState().remove(toast.id);
                            }, size: "sm", children: _jsx(CloseIcon, {}) }))] }) }, toast.id))) }) }));
}

import { jsx as _jsx } from "react/jsx-runtime";
import { AuthRoute } from '../auth/guards/auth-route';
import { NotificationsPage } from './notifications-page';
import { NotificationSettingsPage } from './subscriptions/notification-settings-page';
import { ActiveWorkspaceProvider } from '../workspace/active-workspace-id-context';
export const notificationRoutes = [
    {
        path: '/notifications',
        element: (_jsx(AuthRoute, { children: _jsx(ActiveWorkspaceProvider, { children: _jsx(NotificationsPage, {}) }) })),
    },
    {
        path: '/notifications/settings',
        element: (_jsx(AuthRoute, { children: _jsx(NotificationSettingsPage, {}) })),
    },
];

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useProducts } from './use-products';
import { Button } from '@ui/buttons/button';
import { Trans } from '@ui/i18n/trans';
import { ForumIcon } from '@ui/icons/material/Forum';
import { Navbar } from '../../ui/navigation/navbar/navbar';
import { Link } from 'react-router';
import { Footer } from '../../ui/footer/footer';
import { Fragment, useState } from 'react';
import { BillingCycleRadio } from './billing-cycle-radio';
import { StaticPageTitle } from '../../seo/static-page-title';
import { PricingTable } from '@common/billing/pricing-table/pricing-table';
export function PricingPage() {
    const query = useProducts('pricingPage');
    const [selectedCycle, setSelectedCycle] = useState('yearly');
    return (_jsxs(Fragment, { children: [_jsx(StaticPageTitle, { children: _jsx(Trans, { message: "Pricing" }) }), _jsx(Navbar, { color: "bg", darkModeColor: "transparent", border: "border-b", menuPosition: "pricing-table-page" }), _jsxs("div", { className: "container mx-auto px-24", children: [_jsx("h1", { className: "mb-30 mt-30 text-center text-3xl font-normal md:mt-60 md:text-4xl md:font-medium", children: _jsx(Trans, { message: "Choose the right plan for you" }) }), _jsx(BillingCycleRadio, { products: query.data?.products, selectedCycle: selectedCycle, onChange: setSelectedCycle, className: "mb-40 flex justify-center md:mb-70", size: "lg" }), _jsx(PricingTable, { selectedCycle: selectedCycle, productLoader: "pricingPage" }), _jsx(ContactSection, {})] }), _jsx(Footer, { className: "container mx-auto flex-shrink-0 px-24" })] }));
}
function ContactSection() {
    return (_jsxs("div", { className: "my-20 p-24 text-center md:my-80", children: [_jsx(ForumIcon, { size: "xl", className: "text-muted" }), _jsx("div", { className: "my-8 md:text-lg", children: _jsx(Trans, { message: "Do you have any questions about PRO accounts?" }) }), _jsx("div", { className: "mb-24 mt-20 text-sm md:mt-0 md:text-base", children: _jsx(Trans, { message: "Our support team will be happy to assist you." }) }), _jsx(Button, { variant: "flat", color: "primary", elementType: Link, to: "/contact", children: _jsx(Trans, { message: "Contact us" }) })] }));
}

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useParams } from 'react-router';
import { useCustomPage } from './use-custom-page';
import { Navbar } from '../ui/navigation/navbar/navbar';
import { Footer } from '../ui/footer/footer';
import { CustomPageBody } from '@common/custom-page/custom-page-body';
import { PageMetaTags } from '@common/http/page-meta-tags';
import { PageStatus } from '@common/http/page-status';
import { useEffect } from 'react';
export function CustomPageLayout({ slug }) {
    const { pageSlug } = useParams();
    const query = useCustomPage(slug || pageSlug);
    useEffect(() => {
        if (query.data?.page) {
            window.scrollTo(0, 0);
        }
    }, [query]);
    return (_jsxs("div", { className: "flex min-h-screen flex-col bg", children: [_jsx(PageMetaTags, { query: query }), _jsx(Navbar, { menuPosition: "custom-page-navbar", className: "sticky top-0 flex-shrink-0" }), _jsx("div", { className: "flex-auto", children: query.data ? (_jsx(CustomPageBody, { page: query.data.page })) : (_jsx(PageStatus, { query: query, loaderClassName: "mt-80" })) }), _jsx(Footer, { className: "mx-14 md:mx-40" })] }));
}

import { jsx as _jsx } from "react/jsx-runtime";
import { Navigate, Outlet, replace } from 'react-router';
import { auth, useAuth } from '../use-auth';
import { NotFoundPage } from '@common/ui/not-found-page/not-found-page';
import { useSettings } from '@ui/settings/use-settings';
import { getBootstrapData } from '@ui/bootstrap-data/bootstrap-data-store';
export function AuthRoute({ children, permission, requireLogin = true }) {
    const { isLoggedIn, hasPermission, isSubscribed } = useAuth();
    const { billing } = useSettings();
    if ((requireLogin && !isLoggedIn) ||
        (permission && !hasPermission(permission))) {
        if (isLoggedIn) {
            return billing.enable && !isSubscribed ? (_jsx(Navigate, { to: "/pricing", replace: true })) : (_jsx(NotFoundPage, {}));
        }
        return _jsx(Navigate, { to: "/login", replace: true });
    }
    return children || _jsx(Outlet, {});
}
export function authGuard({ permission, requireLogin = true, } = {}) {
    const billingEnabled = getBootstrapData().settings.billing.enable;
    if ((requireLogin && !auth.isLoggedIn) ||
        (permission && !auth.hasPermission(permission))) {
        if (auth.isLoggedIn) {
            return billingEnabled && !auth.isSubscribed
                ? replace('/pricing')
                : replace('/404');
        }
        return replace('/login');
    }
    return null;
}

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useValueLists } from '../http/value-lists';
import { Button } from '@ui/buttons/button';
import { LanguageIcon } from '@ui/icons/material/Language';
import { KeyboardArrowDownIcon } from '@ui/icons/material/KeyboardArrowDown';
import { Menu, MenuItem, MenuTrigger } from '@ui/menu/menu-trigger';
import { useSelectedLocale } from '@ui/i18n/selected-locale';
import { useChangeLocale } from '@common/locale-switcher/change-locale';
import { useSettings } from '@ui/settings/use-settings';
export function LocaleSwitcher() {
    const { locale } = useSelectedLocale();
    const changeLocale = useChangeLocale();
    const { data } = useValueLists(['localizations']);
    const { i18n } = useSettings();
    if (!data?.localizations || !locale || !i18n.enable)
        return null;
    return (_jsxs(MenuTrigger, { floatingWidth: "matchTrigger", selectionMode: "single", selectedValue: locale.language, onSelectionChange: value => {
            const newLocale = value;
            if (newLocale !== locale?.language) {
                changeLocale.mutate({ locale: newLocale });
            }
        }, children: [_jsx(Button, { disabled: changeLocale.isPending, className: "capitalize", startIcon: _jsx(LanguageIcon, {}), endIcon: _jsx(KeyboardArrowDownIcon, {}), children: locale.name }), _jsx(Menu, { children: data.localizations.map(localization => (_jsx(MenuItem, { value: localization.language, className: "capitalize", children: localization.name }, localization.language))) })] }));
}

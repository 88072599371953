import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useForm } from 'react-hook-form';
import { FormTextField } from '@ui/forms/input-field/text-field/text-field';
import { Button } from '@ui/buttons/button';
import { Form } from '@ui/forms/form';
import { AuthLayout } from '../auth-layout/auth-layout';
import { Trans } from '@ui/i18n/trans';
import { StaticPageTitle } from '@common/seo/static-page-title';
import { useTwoFactorChallenge, } from '@common/auth/ui/two-factor/requests/use-two-factor-challenge';
import { useState } from 'react';
export function TwoFactorChallengePage() {
    const [usingRecoveryCode, setUsingRecoveryCode] = useState(false);
    const form = useForm();
    const completeChallenge = useTwoFactorChallenge(form);
    return (_jsxs(AuthLayout, { children: [_jsx(StaticPageTitle, { children: _jsx(Trans, { message: "Two factor authentication" }) }), _jsxs(Form, { form: form, onSubmit: payload => {
                    completeChallenge.mutate(payload);
                }, children: [_jsx("div", { className: "mb-32 text-sm", children: _jsx(Trans, { message: "Confirm access to your account by entering the authentication code provided by your authenticator application." }) }), _jsx("div", { className: "mb-4", children: usingRecoveryCode ? (_jsx(FormTextField, { name: "recovery_code", minLength: 21, maxLength: 21, autoComplete: "off", autoCorrect: "off", spellCheck: "false", label: _jsx(Trans, { message: "Recovery code" }), autoFocus: true, required: true })) : (_jsx(FormTextField, { name: "code", minLength: 6, maxLength: 6, autoComplete: "off", autoCorrect: "off", spellCheck: "false", label: _jsx(Trans, { message: "Code" }), autoFocus: true, required: true })) }), _jsx("div", { className: "mb-32", children: _jsx(Button, { variant: "link", color: "primary", size: "sm", onClick: () => setUsingRecoveryCode(!usingRecoveryCode), children: _jsx(Trans, { message: "Use recovery code instead" }) }) }), _jsx(Button, { className: "block w-full", type: "submit", variant: "flat", color: "primary", size: "md", disabled: completeChallenge.isPending, children: _jsx(Trans, { message: "Continue" }) })] })] }));
}

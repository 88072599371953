import { jsx as _jsx } from "react/jsx-runtime";
import { auth, useAuth } from '../use-auth';
import { Navigate, Outlet, replace } from 'react-router';
export function NotSubscribedRoute({ children }) {
    const { isLoggedIn, isSubscribed } = useAuth();
    if (!isLoggedIn) {
        return _jsx(Navigate, { to: "/register", replace: true });
    }
    if (isLoggedIn && isSubscribed) {
        return _jsx(Navigate, { to: "/billing", replace: true });
    }
    return children || _jsx(Outlet, {});
}
export function notSubscribedGuard() {
    if (!auth.isLoggedIn) {
        return replace('/register');
    }
    if (auth.isLoggedIn && auth.isSubscribed) {
        return replace('/billing');
    }
    return null;
}

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTrans } from '@ui/i18n/use-trans';
import { useSettings } from '@ui/settings/use-settings';
import { Link } from 'react-router';
import clsx from 'clsx';
import { useContext } from 'react';
import { SiteConfigContext } from '@common/core/settings/site-config-context';
import { useAuth } from '@common/auth/use-auth';
export function Logo({ color = 'light', className: propsClassName, logoType = 'auto', size, }) {
    const className = clsx(propsClassName, size);
    if (logoType === 'compact') {
        return _jsx(CompactLogo, { color: color, className: className });
    }
    else if (logoType === 'wide') {
        return _jsx(WideLogo, { color: color, className: className });
    }
    return _jsx(AutoLogo, { color: color, className: className });
}
function CompactLogo({ color, className }) {
    const { branding } = useSettings();
    // fallback to light logo if dark logo is not available
    const src = color === 'dark' && branding.logo_dark_mobile
        ? branding.logo_dark_mobile
        : branding.logo_light_mobile;
    if (!src)
        return null;
    return (_jsx(WrapperLink, { className: className, children: _jsx("img", { src: src, className: "block w-auto", alt: "" }) }));
}
function AutoLogo({ color, className }) {
    const { branding } = useSettings();
    let wideLogo;
    let compactLogo;
    if (color === 'light') {
        wideLogo = branding.logo_light;
        compactLogo = branding.logo_light_mobile;
    }
    else {
        wideLogo = branding.logo_dark;
        compactLogo = branding.logo_dark_mobile;
    }
    if (!wideLogo && !compactLogo) {
        return null;
    }
    return (_jsx(WrapperLink, { className: className, children: _jsxs("picture", { children: [_jsx("source", { srcSet: compactLogo || wideLogo, media: "(max-width: 768px)" }), _jsx("source", { srcSet: wideLogo, media: "(min-width: 768px)" }), _jsx("img", { className: "block h-full w-auto", alt: "" })] }) }));
}
function WideLogo({ color, className }) {
    const { branding } = useSettings();
    const src = color === 'dark' && branding.logo_dark
        ? branding.logo_dark
        : branding.logo_light;
    if (!src)
        return null;
    return (_jsx(WrapperLink, { className: className, children: _jsx("img", { src: src, className: "block h-full w-auto", alt: "" }) }));
}
function WrapperLink({ className, children }) {
    const { trans } = useTrans();
    const { isLoggedIn } = useAuth();
    const { auth } = useContext(SiteConfigContext);
    return (_jsx(Link, { to: isLoggedIn ? auth.redirectUri : '/', className: clsx('block flex-shrink-0', className), "aria-label": trans({ message: 'Go to homepage' }), children: children }));
}

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AnimatePresence } from 'framer-motion';
import { cloneElement, useEffect, useMemo, useRef, } from 'react';
import clsx from 'clsx';
import { ListBoxContext, useListboxContext } from './listbox-context';
import { useIsMobileDevice } from '@ui/utils/hooks/is-mobile-device';
import { Popover } from '@ui/overlays/popover';
import { Tray } from '@ui/overlays/tray';
import { Trans } from '@ui/i18n/trans';
import { createPortal } from 'react-dom';
import { rootEl } from '@ui/root-el';
export function Listbox({ listbox, children: trigger, isLoading, mobileOverlay = Tray, searchField, onClose, prepend, className: listboxClassName, ...domProps }) {
    const isMobile = useIsMobileDevice();
    const { floatingWidth, floatingMinWidth = 'min-w-180', collection, showEmptyMessage, state: { isOpen, setIsOpen }, positionStyle, floating, refs, } = listbox;
    const Overlay = !prepend && isMobile ? mobileOverlay : Popover;
    const className = clsx('text-base sm:text-sm outline-none bg max-h-inherit flex flex-col', !prepend && 'shadow-xl border py-4', listboxClassName, 
    // tray will apply its own rounding and max width
    Overlay === Popover && 'rounded-panel', Overlay === Popover && floatingWidth === 'auto'
        ? `max-w-288 ${floatingMinWidth}`
        : '');
    const children = useMemo(() => {
        let sectionIndex = 0;
        const renderedSections = [];
        return [...collection.values()].reduce((prev, curr) => {
            if (!curr.section) {
                prev.push(cloneElement(curr.element, {
                    key: curr.element.key || curr.element.props.value,
                }));
            }
            else if (!renderedSections.includes(curr.section)) {
                const section = cloneElement(curr.section, {
                    key: curr.section.key || sectionIndex,
                    index: sectionIndex,
                });
                prev.push(section);
                // clone element will create new instance of object, need to keep
                // track of original instance so sections are not duplicated
                renderedSections.push(curr.section);
                sectionIndex++;
            }
            return prev;
        }, []);
    }, [collection]);
    const showContent = children.length > 0 || (showEmptyMessage && !isLoading);
    const innerContent = showContent ? (_jsxs("div", { className: className, role: "presentation", children: [searchField, _jsx(FocusContainer, { isLoading: isLoading, ...domProps, children: children })] })) : null;
    return (_jsxs(ListBoxContext.Provider, { value: listbox, children: [trigger, prepend
                ? innerContent
                : rootEl &&
                    createPortal(_jsx(AnimatePresence, { children: isOpen && showContent && (_jsx(Overlay, { triggerRef: refs.reference, restoreFocus: true, isOpen: isOpen, onClose: () => {
                                onClose?.();
                                setIsOpen(false);
                            }, isDismissable: true, style: positionStyle, ref: floating, children: innerContent })) }), rootEl)] }));
}
function FocusContainer({ className, children, isLoading, ...domProps }) {
    const { role, listboxId, virtualFocus, focusItem, state: { activeIndex, setActiveIndex, selectedIndex }, } = useListboxContext();
    const autoFocusRef = useRef(true);
    const domRef = useRef(null);
    // reset activeIndex on unmount
    useEffect(() => {
        return () => setActiveIndex(null);
    }, [setActiveIndex]);
    // focus active index or menu on mount, because menu will be closed
    // on trigger keyDown and focus won't be applied to items
    useEffect(() => {
        if (autoFocusRef.current) {
            const indexToFocus = activeIndex ?? selectedIndex;
            // if no activeIndex, focus menu itself
            if (indexToFocus == null && !virtualFocus) {
                requestAnimationFrame(() => {
                    domRef.current?.focus({ preventScroll: true });
                });
            }
            else if (indexToFocus != null) {
                // wait until next frame, otherwise auto scroll might not work
                requestAnimationFrame(() => {
                    focusItem('increment', indexToFocus);
                });
            }
        }
        autoFocusRef.current = false;
    }, [activeIndex, selectedIndex, focusItem, virtualFocus]);
    return (_jsx("div", { tabIndex: virtualFocus ? undefined : -1, role: role, id: listboxId, className: "flex-auto overflow-y-auto overscroll-contain outline-none", ref: domRef, ...domProps, children: children.length ? children : _jsx(EmptyMessage, {}) }));
}
function EmptyMessage() {
    return (_jsx("div", { className: "px-8 py-4 text-sm italic text-muted", children: _jsx(Trans, { message: "There are no items matching your query" }) }));
}

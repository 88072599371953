import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Navbar } from '@common/ui/navigation/navbar/navbar';
import { useUser } from '../use-user';
import { ProgressCircle } from '@ui/progress/progress-circle';
import { SocialLoginPanel } from './social-login-panel';
import { BasicInfoPanel } from './basic-info-panel/basic-info-panel';
import { ChangePasswordPanel } from './change-password-panel/change-password-panel';
import { LocalizationPanel } from './localization-panel';
import { AccessTokenPanel } from './access-token-panel/access-token-panel';
import { DangerZonePanel } from './danger-zone-panel/danger-zone-panel';
import { Trans } from '@ui/i18n/trans';
import { StaticPageTitle } from '@common/seo/static-page-title';
import { AccountSettingsSidenav } from '@common/auth/ui/account-settings/account-settings-sidenav';
import { SessionsPanel } from '@common/auth/ui/account-settings/sessions-panel/sessions-panel';
import { useContext } from 'react';
import { SiteConfigContext } from '@common/core/settings/site-config-context';
import { TwoFactorPanel } from '@common/auth/ui/account-settings/two-factor-panel';
export function AccountSettingsPage() {
    const { auth } = useContext(SiteConfigContext);
    const { data, isLoading } = useUser('me', {
        with: ['roles', 'social_profiles', 'tokens'],
    });
    return (_jsxs("div", { className: "min-h-screen bg-alt", children: [_jsx(StaticPageTitle, { children: _jsx(Trans, { message: "Account Settings" }) }), _jsx(Navbar, { menuPosition: "account-settings-page" }), _jsx("div", { children: _jsxs("div", { className: "container mx-auto px-24 py-24", children: [_jsx("h1", { className: "text-3xl", children: _jsx(Trans, { message: "Account settings" }) }), _jsx("div", { className: "mb-40 text-base text-muted", children: _jsx(Trans, { message: "View and update your account details, profile and more." }) }), isLoading || !data ? (_jsx(ProgressCircle, { className: "my-80", "aria-label": "Loading user..", isIndeterminate: true })) : (_jsxs("div", { className: "flex items-start gap-24", children: [_jsx(AccountSettingsSidenav, {}), _jsxs("main", { className: "flex-auto", children: [auth.accountSettingsPanels?.map(panel => (_jsx(panel.component, { user: data.user }, panel.id))), _jsx(BasicInfoPanel, { user: data.user }), _jsx(SocialLoginPanel, { user: data.user }), _jsx(ChangePasswordPanel, {}), _jsx(TwoFactorPanel, { user: data.user }), _jsx(SessionsPanel, {}), _jsx(LocalizationPanel, { user: data.user }), _jsx(AccessTokenPanel, { user: data.user }), _jsx(DangerZonePanel, {})] })] }))] }) })] }));
}

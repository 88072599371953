import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import useClipboard from 'react-use-clipboard';
import { Dialog } from '@ui/overlays/dialog/dialog';
import { DialogHeader } from '@ui/overlays/dialog/dialog-header';
import { DialogBody } from '@ui/overlays/dialog/dialog-body';
import { Form } from '@ui/forms/form';
import { FormTextField, TextField, } from '@ui/forms/input-field/text-field/text-field';
import { useDialogContext } from '@ui/overlays/dialog/dialog-context';
import { DialogFooter } from '@ui/overlays/dialog/dialog-footer';
import { Button } from '@ui/buttons/button';
import { useCreateAccessToken, } from './create-new-token';
import { ErrorIcon } from '@ui/icons/material/Error';
import { Trans } from '@ui/i18n/trans';
import { queryClient } from '@common/http/query-client';
export function CreateNewTokenDialog() {
    const form = useForm();
    const { formId, close } = useDialogContext();
    const createToken = useCreateAccessToken(form);
    const [plainTextToken, setPlainTextToken] = useState();
    const formNode = (_jsx(Form, { form: form, id: formId, onSubmit: values => {
            createToken.mutate(values, {
                onSuccess: response => {
                    setPlainTextToken(response.plainTextToken);
                    queryClient.invalidateQueries({ queryKey: ['users'] });
                },
            });
        }, children: _jsx(FormTextField, { name: "tokenName", label: _jsx(Trans, { message: "Token name" }), required: true, autoFocus: true }) }));
    return (_jsxs(Dialog, { children: [_jsx(DialogHeader, { children: _jsx(Trans, { message: "Create new token" }) }), _jsx(DialogBody, { children: plainTextToken ? (_jsx(PlainTextPreview, { plainTextToken: plainTextToken })) : (formNode) }), _jsxs(DialogFooter, { children: [_jsx(Button, { variant: "text", onClick: close, children: _jsx(Trans, { message: "Done" }) }), !plainTextToken && (_jsx(Button, { variant: "flat", color: "primary", type: "submit", form: formId, disabled: createToken.isPending, children: _jsx(Trans, { message: "Create" }) }))] })] }));
}
function PlainTextPreview({ plainTextToken }) {
    const [isCopied, copyToClipboard] = useClipboard(plainTextToken || '', {
        successDuration: 1000,
    });
    return (_jsxs(_Fragment, { children: [_jsx(TextField, { readOnly: true, value: plainTextToken, autoFocus: true, onClick: e => {
                    e.currentTarget.focus();
                    e.currentTarget.select();
                }, endAppend: _jsx(Button, { variant: "flat", color: "primary", onClick: copyToClipboard, children: isCopied ? _jsx(Trans, { message: "Copied!" }) : _jsx(Trans, { message: "Copy" }) }) }), _jsxs("div", { className: "mt-14 flex items-center gap-10 text-sm", children: [_jsx(ErrorIcon, { size: "sm", className: "text-danger" }), _jsx(Trans, { message: "Make sure to store the token in a safe place. After this dialog is closed, token will not be viewable anymore." })] })] }));
}

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { cloneElement } from 'react';
import { useSocialLogin } from '../../requests/use-social-login';
import { toast } from '@ui/toast/toast';
import { Button } from '@ui/buttons/button';
import { EnvatoIcon } from '@ui/icons/social/envato';
import { GoogleIcon } from '@ui/icons/social/google';
import { FacebookIcon } from '@ui/icons/social/facebook';
import { TwitterIcon } from '@ui/icons/social/twitter';
import { AccountSettingsPanel } from './account-settings-panel';
import { Trans } from '@ui/i18n/trans';
import { message } from '@ui/i18n/message';
import { useSettings } from '@ui/settings/use-settings';
import { queryClient } from '@common/http/query-client';
import { AccountSettingsId } from '@common/auth/ui/account-settings/account-settings-sidenav';
import { useAllSocialLoginsDisabled } from '@common/auth/ui/use-all-social-logins-disabled';
export function SocialLoginPanel({ user }) {
    if (useAllSocialLoginsDisabled()) {
        return null;
    }
    return (_jsxs(AccountSettingsPanel, { id: AccountSettingsId.SocialLogin, title: _jsx(Trans, { message: "Manage social login" }), children: [_jsx(SocialLoginPanelRow, { icon: _jsx(EnvatoIcon, { viewBox: "0 0 50 50", className: "border-envato bg-envato text-white" }), service: "envato", user: user }), _jsx(SocialLoginPanelRow, { icon: _jsx(GoogleIcon, { viewBox: "0 0 48 48" }), service: "google", user: user }), _jsx(SocialLoginPanelRow, { icon: _jsx(FacebookIcon, { className: "text-facebook" }), service: "facebook", user: user }), _jsx(SocialLoginPanelRow, { icon: _jsx(TwitterIcon, { className: "text-twitter" }), service: "twitter", user: user }), _jsx("div", { className: "pb-6 pt-16 text-sm text-muted", children: _jsx(Trans, { message: "If you disable social logins, you'll still be able to log in using your email and password." }) })] }));
}
function SocialLoginPanelRow({ service, user, className, icon, }) {
    const { social } = useSettings();
    const { connectSocial, disconnectSocial } = useSocialLogin();
    const username = user?.social_profiles?.find(s => s.service_name === service)
        ?.username;
    if (!social?.[service]?.enable) {
        return null;
    }
    return (_jsxs("div", { className: clsx('flex items-center gap-14 border-b px-10 py-20', className), children: [cloneElement(icon, {
                size: 'xl',
                className: clsx(icon.props.className, 'border p-8 rounded'),
            }), _jsxs("div", { className: "mr-auto overflow-hidden text-ellipsis whitespace-nowrap", children: [_jsx("div", { className: "overflow-hidden text-ellipsis text-sm font-bold first-letter:capitalize", children: _jsx(Trans, { message: ":service account", values: { service } }) }), _jsx("div", { className: "mt-2 text-xs", children: username || _jsx(Trans, { message: "Disabled" }) })] }), _jsx(Button, { disabled: disconnectSocial.isPending, size: "xs", variant: "outline", color: username ? 'danger' : 'primary', onClick: async () => {
                    if (username) {
                        disconnectSocial.mutate({ service }, {
                            onSuccess: () => {
                                queryClient.invalidateQueries({ queryKey: ['users'] });
                                toast(message('Disabled :service account', { values: { service } }));
                            },
                        });
                    }
                    else {
                        const e = await connectSocial(service);
                        if (e?.status === 'SUCCESS') {
                            queryClient.invalidateQueries({ queryKey: ['users'] });
                            toast(message('Enabled :service account', { values: { service } }));
                        }
                    }
                }, children: username ? _jsx(Trans, { message: "Disable" }) : _jsx(Trans, { message: "Enable" }) })] }));
}

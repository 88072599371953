import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useListbox } from '@ui/forms/listbox/use-listbox';
import { Menu } from '@ui/menu/menu-trigger';
import { useListboxKeyboardNavigation } from '@ui/forms/listbox/use-listbox-keyboard-navigation';
import { useTypeSelect } from '@ui/forms/listbox/use-type-select';
import { Listbox } from '@ui/forms/listbox/listbox';
const preventContextOnMenu = (e) => {
    e.preventDefault();
};
export function ContextMenu({ position, children, ...props }) {
    const listbox = useListbox({
        ...props,
        isOpen: props.isOpen && !!position,
        placement: 'right-start',
        floatingWidth: 'auto',
        role: 'menu',
        focusLoopingMode: 'loop',
        children: children?.type === Menu
            ? children.props.children
            : children,
    });
    const { reference, refs, state: { isOpen, setIsOpen, activeIndex }, focusItem, listContent, } = listbox;
    useEffect(() => {
        if (refs.floating.current) {
            refs.floating.current.addEventListener('contextmenu', preventContextOnMenu);
            return () => {
                refs.floating.current?.removeEventListener('contextmenu', preventContextOnMenu);
            };
        }
    }, [refs.floating]);
    useEffect(() => {
        if (position) {
            reference(pointToVirtualElement(position));
            setIsOpen(true);
        }
        else {
            setIsOpen(false);
        }
    }, [position, reference, setIsOpen]);
    const { handleListboxKeyboardNavigation } = useListboxKeyboardNavigation(listbox);
    const { findMatchingItem } = useTypeSelect();
    return (_jsx(Listbox, { listbox: listbox, onKeyDownCapture: e => {
            if (!isOpen)
                return;
            const i = findMatchingItem(e, listContent, activeIndex);
            if (i) {
                focusItem('increment', i);
            }
        }, onKeyDown: handleListboxKeyboardNavigation }));
}
export function pointToVirtualElement({ x, y }, contextElement) {
    return {
        getBoundingClientRect() {
            return {
                x,
                y,
                width: 0,
                height: 0,
                top: y,
                right: x,
                bottom: y,
                left: x,
            };
        },
        contextElement,
    };
}

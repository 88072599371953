import { jsx as _jsx } from "react/jsx-runtime";
import { ContactUsPage } from '@common/contact/contact-us-page';
import { CustomPageLayout } from '@common/custom-page/custom-page-layout';
import { NotFoundPage } from '@common/ui/not-found-page/not-found-page';
export const commonRoutes = [
    {
        path: 'contact',
        element: _jsx(ContactUsPage, {}),
    },
    {
        path: 'pages/:pageSlug',
        element: _jsx(CustomPageLayout, {}),
    },
    {
        path: 'pages/:pageId/:pageSlug',
        element: _jsx(CustomPageLayout, {}),
    },
    {
        path: '404',
        element: _jsx(NotFoundPage, {}),
    },
];

import { useEffect, useState } from 'react';
export function usePreferredColorScheme() {
    const [scheme, setScheme] = useState(() => {
        if (!window.matchMedia) {
            return 'light';
        }
        return window.matchMedia('(prefers-color-scheme: dark)').matches
            ? 'dark'
            : 'light';
    });
    useEffect(() => {
        const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
        const listener = () => {
            setScheme(mediaQuery.matches ? 'dark' : 'light');
        };
        mediaQuery.addEventListener('change', listener);
        return () => {
            mediaQuery.removeEventListener('change', listener);
        };
    }, []);
    return scheme;
}

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import { GroupAddIcon } from '@ui/icons/material/GroupAdd';
import { PeopleIcon } from '@ui/icons/material/People';
import { FileDownloadDoneIcon } from '@ui/icons/material/FileDownloadDone';
import { MixedImage } from '@ui/images/mixed-image';
import { Button } from '@ui/buttons/button';
import { SiteConfigContext } from '@common/core/settings/site-config-context';
import { Line } from '@common/notifications/notification-line';
import clsx from 'clsx';
import { useMarkNotificationsAsRead } from '@common/notifications/requests/use-mark-notifications-as-read';
import { useNavigate } from '@common/ui/navigation/use-navigate';
import { isAbsoluteUrl } from '@ui/utils/urls/is-absolute-url';
import { Link } from 'react-router';
import { useSettings } from '@ui/settings/use-settings';
const iconMap = {
    'group-add': GroupAddIcon,
    people: PeopleIcon,
    'export-csv': FileDownloadDoneIcon,
};
export function NotificationList({ notifications, className, }) {
    const { notifications: config } = useContext(SiteConfigContext);
    return (_jsx("div", { className: className, children: notifications.map((notification, index) => {
            const isLast = notifications.length - 1 === index;
            const Renderer = config?.renderMap?.[notification.type] || NotificationListItem;
            return (_jsx(Renderer, { notification: notification, isLast: isLast }, notification.id));
        }) }));
}
export function NotificationListItem({ notification, onActionButtonClick, lineIconRenderer, isLast, }) {
    const markAsRead = useMarkNotificationsAsRead();
    const navigate = useNavigate();
    const mainAction = notification.data.mainAction;
    const showUnreadIndicator = !notification.data.image && !notification.read_at;
    return (_jsxs("div", { onClick: () => {
            if (!markAsRead.isPending && !notification.read_at) {
                markAsRead.mutate({ ids: [notification.id] });
            }
            if (mainAction?.action) {
                if (isAbsoluteUrl(mainAction.action)) {
                    window.open(mainAction.action, '_blank')?.focus();
                }
                else {
                    navigate(mainAction.action);
                }
            }
        }, className: clsx('relative flex items-start gap-14 bg-alt px-32 py-20', !isLast && 'border-b', mainAction?.action && 'cursor-pointer', !notification.read_at
            ? 'bg-paper hover:bg-primary/10'
            : 'hover:bg-hover'), title: mainAction?.label ? mainAction.label : undefined, children: [showUnreadIndicator && (_jsx("div", { className: "absolute left-16 top-26 h-8 w-8 flex-shrink-0 rounded-full bg-primary shadow" })), notification.data.image && (_jsx(MixedImage, { className: "h-24 w-24 flex-shrink-0 text-muted", src: iconMap[notification.data.image] || notification.data.image })), _jsxs("div", { className: "min-w-0", children: [notification.data.lines.map((line, index) => (_jsx(Line, { iconRenderer: lineIconRenderer, notification: notification, line: line, index: index }, index))), _jsx(ButtonActions, { onActionClick: onActionButtonClick, notification: notification })] })] }));
}
function ButtonActions({ notification, onActionClick }) {
    const { base_url } = useSettings();
    if (!notification.data.buttonActions)
        return null;
    // if there's no action handler provided, assume action is internal url and render a link
    return (_jsx("div", { className: "mt-12 flex items-center gap-12", children: notification.data.buttonActions.map((action, index) => (_jsx(Button, { size: "xs", variant: index === 0 ? 'flat' : 'outline', color: index === 0 ? 'primary' : null, elementType: !onActionClick ? Link : undefined, to: !onActionClick ? action.action.replace(base_url, '') : undefined, onClick: e => {
                onActionClick?.(e, action);
            }, children: action.label }, index))) }));
}

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { CustomMenu } from '../../menus/custom-menu';
import { LocaleSwitcher } from '@common/locale-switcher/locale-switcher';
import { Button } from '@ui/buttons/button';
import { DarkModeIcon } from '@ui/icons/material/DarkMode';
import { LightbulbIcon } from '@ui/icons/material/Lightbulb';
import { Trans } from '@ui/i18n/trans';
import { useThemeSelector } from '@ui/themes/theme-selector-context';
import { useSettings } from '@ui/settings/use-settings';
export function Footer({ className, padding }) {
    const year = new Date().getFullYear();
    const { branding } = useSettings();
    return (_jsxs("footer", { className: clsx('text-sm', padding ? padding : 'pb-28 pt-54 md:pb-54', className), children: [_jsx(Menus, {}), _jsxs("div", { className: "items-center justify-between gap-30 text-center text-muted md:flex md:text-left", children: [_jsx(Trans, { message: "Copyright \u00A9 :year :name, All Rights Reserved", values: { year, name: branding.site_name } }), _jsxs("div", { children: [_jsx(ThemeSwitcher, {}), _jsx(LocaleSwitcher, {})] })] })] }));
}
function Menus() {
    const settings = useSettings();
    const primaryMenu = settings.menus.find(m => m.positions?.includes('footer'));
    const secondaryMenu = settings.menus.find(m => m.positions?.includes('footer-secondary'));
    if (!primaryMenu && !secondaryMenu)
        return null;
    return (_jsxs("div", { className: "mb-14 items-center justify-between gap-30 overflow-x-auto border-b pb-14 md:flex", children: [primaryMenu && (_jsx(CustomMenu, { menu: primaryMenu, className: "text-primary" })), secondaryMenu && (_jsx(CustomMenu, { menu: secondaryMenu, className: "mb:mt-0 mt-14 text-muted" }))] }));
}
function ThemeSwitcher() {
    const { themes } = useSettings();
    const { selectedTheme, selectTheme } = useThemeSelector();
    if (!selectedTheme || !themes?.user_change)
        return null;
    return (_jsx(Button, { variant: "text", startIcon: selectedTheme.is_dark ? _jsx(DarkModeIcon, {}) : _jsx(LightbulbIcon, {}), onClick: () => {
            if (selectedTheme.is_dark) {
                selectTheme('light');
            }
            else {
                selectTheme('dark');
            }
        }, children: selectedTheme.is_dark ? (_jsx(Trans, { message: "Light mode" })) : (_jsx(Trans, { message: "Dark mode" })) }));
}

import { useSelectedLocale } from '@ui/i18n/selected-locale';
const cache = new Map();
export function useCollator(options) {
    const { localeCode } = useSelectedLocale();
    const cacheKey = localeCode +
        (options
            ? Object.entries(options)
                .sort((a, b) => (a[0] < b[0] ? -1 : 1))
                .join()
            : '');
    if (cache.has(cacheKey)) {
        return cache.get(cacheKey);
    }
    const formatter = new Intl.Collator(localeCode, options);
    cache.set(cacheKey, formatter);
    return formatter;
}

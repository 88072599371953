import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from 'react-router';
import clsx from 'clsx';
import { AccountSettingsPanel } from '../account-settings-panel';
import { IllustratedMessage } from '@ui/images/illustrated-message';
import { SvgImage } from '@ui/images/svg-image';
import { Button } from '@ui/buttons/button';
import { FormattedDate } from '@ui/i18n/formatted-date';
import { DialogTrigger } from '@ui/overlays/dialog/dialog-trigger';
import { ConfirmationDialog } from '@ui/overlays/dialog/confirmation-dialog';
import { useDeleteAccessToken } from './delete-access-token';
import { CreateNewTokenDialog } from './create-new-token-dialog';
import { LinkStyle } from '@ui/buttons/external-link';
import { useAuth } from '../../../use-auth';
import { Trans } from '@ui/i18n/trans';
import secureFilesSvg from './secure-files.svg';
import { useSettings } from '@ui/settings/use-settings';
import { queryClient } from '@common/http/query-client';
import { AccountSettingsId } from '@common/auth/ui/account-settings/account-settings-sidenav';
export function AccessTokenPanel({ user }) {
    const tokens = user.tokens || [];
    const { hasPermission, user: authUser } = useAuth();
    const { api } = useSettings();
    if (!api?.integrated || !hasPermission('api.access'))
        return null;
    return (_jsx(AccountSettingsPanel, { id: AccountSettingsId.Developers, title: _jsx(Trans, { message: "API access tokens" }), titleSuffix: _jsx(Link, { className: LinkStyle, to: "/api-docs", target: "_blank", children: _jsx(Trans, { message: "Documentation" }) }), actions: _jsx(CreateNewTokenButton, {}), children: !tokens.length ? (_jsx(IllustratedMessage, { className: "py-40", image: _jsx(SvgImage, { src: secureFilesSvg }), title: user.id === authUser?.id ? (_jsx(Trans, { message: "You have no personal access tokens yet" })) : (_jsx(Trans, { message: "User has not created any access tokens yet" })) })) : (tokens.map((token, index) => (_jsx(TokenLine, { token: token, isLast: index === tokens.length - 1 }, token.id)))) }));
}
function TokenLine({ token, isLast }) {
    return (_jsxs("div", { className: clsx('flex items-center gap-24', !isLast && 'mb-12 border-b pb-12'), children: [_jsxs("div", { className: "text-sm", children: [_jsx("div", { className: "font-semibold", children: _jsx(Trans, { message: "Name" }) }), _jsx("div", { children: token.name }), _jsx("div", { className: "mt-10 font-semibold", children: _jsx(Trans, { message: "Last used" }) }), _jsx("div", { children: token.last_used_at ? (_jsx(FormattedDate, { date: token.last_used_at })) : (_jsx(Trans, { message: "Never" })) })] }), _jsx(DeleteTokenButton, { token: token })] }));
}
function CreateNewTokenButton() {
    return (_jsxs(DialogTrigger, { type: "modal", children: [_jsx(Button, { variant: "flat", color: "primary", children: _jsx(Trans, { message: "Create token" }) }), _jsx(CreateNewTokenDialog, {})] }));
}
function DeleteTokenButton({ token }) {
    const deleteToken = useDeleteAccessToken();
    return (_jsxs(DialogTrigger, { type: "modal", onClose: isConfirmed => {
            if (isConfirmed) {
                deleteToken.mutate({ id: token.id }, {
                    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['users'] }),
                });
            }
        }, children: [_jsx(Button, { size: "xs", variant: "outline", color: "danger", className: "ml-auto flex-shrink-0", children: _jsx(Trans, { message: "Delete" }) }), _jsx(ConfirmationDialog, { isDanger: true, title: _jsx(Trans, { message: "Delete token?" }), body: _jsx(Trans, { message: "This token will be deleted immediately and permanently. Once deleted, it can no longer be used to make API requests." }), confirm: _jsx(Trans, { message: "Delete" }) })] }));
}

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link, Navigate, useLocation, useSearchParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { FormTextField } from '@ui/forms/input-field/text-field/text-field';
import { Button } from '@ui/buttons/button';
import { Form } from '@ui/forms/form';
import { LinkStyle } from '@ui/buttons/external-link';
import { useRegister } from '../requests/use-register';
import { SocialAuthSection } from './social-auth-section';
import { AuthLayout } from './auth-layout/auth-layout';
import { Trans } from '@ui/i18n/trans';
import { FormCheckbox } from '@ui/forms/toggle/checkbox';
import { CustomMenuItem } from '../../menus/custom-menu';
import { useRecaptcha } from '../../recaptcha/use-recaptcha';
import { StaticPageTitle } from '../../seo/static-page-title';
import { useSettings } from '@ui/settings/use-settings';
import { useContext } from 'react';
import { SiteConfigContext } from '@common/core/settings/site-config-context';
export function RegisterPage({ inviteType }) {
    const { branding, registration, social } = useSettings();
    const { auth } = useContext(SiteConfigContext);
    const { verify, isVerifying } = useRecaptcha('register');
    const { pathname } = useLocation();
    const [searchParams] = useSearchParams();
    const isRegisteringUsingInvite = pathname.includes('workspace') || !!inviteType;
    const isBillingRegister = searchParams.get('redirectFrom') === 'pricing';
    const searchParamsEmail = searchParams.get('email') || undefined;
    const form = useForm({
        defaultValues: { email: searchParamsEmail },
    });
    const register = useRegister(form);
    if (registration?.disable && !isRegisteringUsingInvite) {
        return _jsx(Navigate, { to: "/login", replace: true });
    }
    let heading = _jsx(Trans, { message: "Create a new account" });
    if (isRegisteringUsingInvite) {
        heading = (_jsx(Trans, { values: { siteName: branding?.site_name }, message: "To join your team on :siteName, create an account" }));
    }
    else if (isBillingRegister) {
        heading = _jsx(Trans, { message: "First, let's create your account" });
    }
    const message = (_jsx(Trans, { values: {
            a: parts => (_jsx(Link, { className: LinkStyle, to: "/login", children: parts })),
        }, message: "Already have an account? <a>Sign in.</a>" }));
    return (_jsxs(AuthLayout, { heading: heading, message: message, children: [_jsx(StaticPageTitle, { children: _jsx(Trans, { message: "Register" }) }), _jsxs(Form, { form: form, onSubmit: async (payload) => {
                    const isValid = await verify();
                    if (isValid) {
                        register.mutate({
                            ...payload,
                            invite_type: inviteType,
                        });
                    }
                }, children: [_jsx(FormTextField, { className: "mb-32", name: "email", type: "email", disabled: !!searchParamsEmail, label: _jsx(Trans, { message: "Email" }), required: true }), _jsx(FormTextField, { className: "mb-32", name: "password", type: "password", label: _jsx(Trans, { message: "Password" }), required: true }), _jsx(FormTextField, { className: "mb-32", name: "password_confirmation", type: "password", label: _jsx(Trans, { message: "Confirm password" }), required: true }), auth?.registerFields ? _jsx(auth.registerFields, {}) : null, _jsx(PolicyCheckboxes, {}), _jsx(Button, { className: "block w-full", type: "submit", variant: "flat", color: "primary", size: "md", disabled: register.isPending || isVerifying, children: _jsx(Trans, { message: "Create account" }) }), _jsx(SocialAuthSection, { dividerMessage: social?.compact_buttons ? (_jsx(Trans, { message: "Or sign up with" })) : (_jsx(Trans, { message: "OR" })) })] })] }));
}
function PolicyCheckboxes() {
    const { registration } = useSettings();
    if (!registration?.policies)
        return null;
    return (_jsx("div", { className: "mb-32", children: registration.policies.map(policy => (_jsx(FormCheckbox, { name: policy.id, className: "mb-4 block", required: true, children: _jsx(Trans, { message: "I accept the :name", values: {
                    name: (_jsx(CustomMenuItem, { unstyled: true, className: () => LinkStyle, item: policy })),
                } }) }, policy.id))) }));
}

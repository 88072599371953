import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef } from 'react';
import { highlightAllCode } from '@common/text-editor/highlight/highlight-code';
export function CustomPageBody({ page }) {
    const bodyRef = useRef(null);
    useEffect(() => {
        if (bodyRef.current) {
            highlightAllCode(bodyRef.current);
        }
    }, []);
    return (_jsx("div", { className: "px-16 md:px-24", children: _jsxs("div", { className: "custom-page-body prose mx-auto my-50 dark:prose-invert", children: [_jsx("h1", { children: page.title }), _jsx("div", { ref: bodyRef, className: "whitespace-pre-wrap break-words", dangerouslySetInnerHTML: { __html: page.body } })] }) }));
}

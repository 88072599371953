import { jsx as _jsx } from "react/jsx-runtime";
import { useEnableTwoFactor } from '@common/auth/ui/two-factor/requests/use-enable-two-factor';
import { TwoFactorStepperLayout } from '@common/auth/ui/two-factor/stepper/two-factor-stepper-layout';
import { Trans } from '@ui/i18n/trans';
import { Button } from '@ui/buttons/button';
import { usePasswordConfirmedAction } from '@common/auth/ui/confirm-password/use-password-confirmed-action';
export function TwoFactorDisabledStep({ onEnabled }) {
    const enableTwoFactor = useEnableTwoFactor();
    const { withConfirmedPassword, isLoading: confirmPasswordIsLoading } = usePasswordConfirmedAction();
    const isLoading = enableTwoFactor.isPending || confirmPasswordIsLoading;
    return (_jsx(TwoFactorStepperLayout, { title: _jsx(Trans, { message: "You have not enabled two factor authentication." }), actions: _jsx(Button, { variant: "flat", color: "primary", disabled: isLoading, onClick: () => {
                withConfirmedPassword(() => {
                    enableTwoFactor.mutate(undefined, {
                        onSuccess: onEnabled,
                    });
                });
            }, children: _jsx(Trans, { message: "Enable" }) }) }));
}

import { jsx as _jsx } from "react/jsx-runtime";
import { useAuth } from '../use-auth';
import { useContext } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router';
import { useAppearanceEditorMode } from '../../admin/appearance/commands/use-appearance-editor-mode';
import { SiteConfigContext } from '@common/core/settings/site-config-context';
export function GuestRoute({ children }) {
    const { isLoggedIn, getRedirectUri } = useAuth();
    const { isAppearanceEditorActive } = useAppearanceEditorMode();
    const redirectUri = getRedirectUri();
    const { auth } = useContext(SiteConfigContext);
    const { pathname } = useLocation();
    if (isLoggedIn && !isAppearanceEditorActive) {
        // prevent recursive redirects
        if (redirectUri !== pathname) {
            return _jsx(Navigate, { to: redirectUri, replace: true });
        }
        else if (auth.secondaryRedirectUri) {
            return _jsx(Navigate, { to: auth.secondaryRedirectUri, replace: true });
        }
    }
    return children || _jsx(Outlet, {});
}

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { NotificationList } from './notification-list';
import { useUserNotifications } from './dialog/requests/user-notifications';
import { ProgressCircle } from '@ui/progress/progress-circle';
import { NotificationEmptyStateMessage } from './empty-state/notification-empty-state-message';
import { Navbar } from '../ui/navigation/navbar/navbar';
import { Trans } from '@ui/i18n/trans';
import { useMarkNotificationsAsRead } from './requests/use-mark-notifications-as-read';
import { useAuth } from '../auth/use-auth';
import { Button } from '@ui/buttons/button';
import { DoneAllIcon } from '@ui/icons/material/DoneAll';
import { StaticPageTitle } from '../seo/static-page-title';
import { Fragment } from 'react';
import { Footer } from '@common/ui/footer/footer';
import { IconButton } from '@ui/buttons/icon-button';
import { Link } from 'react-router';
import { SettingsIcon } from '@ui/icons/material/Settings';
import { useSettings } from '@ui/settings/use-settings';
export function NotificationsPage() {
    const { user } = useAuth();
    const { data, isLoading } = useUserNotifications({ perPage: 30 });
    const hasUnread = !!user?.unread_notifications_count;
    const markAsRead = useMarkNotificationsAsRead();
    const { notif } = useSettings();
    const handleMarkAsRead = () => {
        if (!data)
            return;
        markAsRead.mutate({
            markAllAsUnread: true,
        });
    };
    const markAsReadButton = (_jsx(Button, { variant: "outline", color: "primary", size: "xs", startIcon: _jsx(DoneAllIcon, {}), onClick: handleMarkAsRead, disabled: markAsRead.isPending || isLoading, className: "ml-auto", children: _jsx(Trans, { message: "Mark all as read" }) }));
    return (_jsxs(Fragment, { children: [_jsx(StaticPageTitle, { children: _jsx(Trans, { message: "Notifications" }) }), _jsx(Navbar, { menuPosition: "notifications-page" }), _jsxs("div", { className: "container mx-auto min-h-[1000px] p-16 md:p-24", children: [_jsxs("div", { className: "mb-30 flex items-center gap-24", children: [_jsx("h1", { className: "text-3xl", children: _jsx(Trans, { message: "Notifications" }) }), hasUnread && markAsReadButton, notif.subs.integrated && (_jsx(IconButton, { className: "ml-auto text-muted", elementType: Link, to: "/notifications/settings", target: "_blank", children: _jsx(SettingsIcon, {}) }))] }), _jsx(PageContent, {})] }), _jsx(Footer, { className: "container mx-auto mt-48 p-16 md:p-24" })] }));
}
function PageContent() {
    const { data, isLoading } = useUserNotifications({ perPage: 30 });
    if (isLoading) {
        return (_jsx("div", { className: "flex items-center justify-center py-10", children: _jsx(ProgressCircle, { "aria-label": "Loading notifications...", isIndeterminate: true }) }));
    }
    if (!data?.pagination.data.length) {
        return _jsx(NotificationEmptyStateMessage, {});
    }
    return (_jsx(NotificationList, { className: "rounded border", notifications: data.pagination.data }));
}

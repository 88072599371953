import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { List, ListItem } from '@ui/list/list';
import { PersonIcon } from '@ui/icons/material/Person';
import { Trans } from '@ui/i18n/trans';
import { LoginIcon } from '@ui/icons/material/Login';
import { LockIcon } from '@ui/icons/material/Lock';
import { PhonelinkLockIcon } from '@ui/icons/material/PhonelinkLock';
import { LanguageIcon } from '@ui/icons/material/Language';
import { ApiIcon } from '@ui/icons/material/Api';
import { DangerousIcon } from '@ui/icons/material/Dangerous';
import { useContext } from 'react';
import { DevicesIcon } from '@ui/icons/material/Devices';
import { useAuth } from '@common/auth/use-auth';
import { useSettings } from '@ui/settings/use-settings';
import { SiteConfigContext } from '@common/core/settings/site-config-context';
import { useAllSocialLoginsDisabled } from '@common/auth/ui/use-all-social-logins-disabled';
export var AccountSettingsId;
(function (AccountSettingsId) {
    AccountSettingsId["AccountDetails"] = "account-details";
    AccountSettingsId["SocialLogin"] = "social-login";
    AccountSettingsId["Password"] = "password";
    AccountSettingsId["TwoFactor"] = "two-factor";
    AccountSettingsId["LocationAndLanguage"] = "location-and-language";
    AccountSettingsId["Developers"] = "developers";
    AccountSettingsId["DeleteAccount"] = "delete-account";
    AccountSettingsId["Sessions"] = "sessions";
})(AccountSettingsId || (AccountSettingsId = {}));
export function AccountSettingsSidenav() {
    const p = AccountSettingsId;
    const { hasPermission } = useAuth();
    const { api } = useSettings();
    const { auth } = useContext(SiteConfigContext);
    const allSocialsDisabled = useAllSocialLoginsDisabled();
    return (_jsx("aside", { className: "sticky top-10 hidden flex-shrink-0 lg:block", children: _jsxs(List, { padding: "p-0", children: [auth.accountSettingsPanels?.map(panel => (_jsx(Item, { icon: _jsx(panel.icon, { viewBox: "0 0 50 50" }), panel: panel.id, children: _jsx(Trans, { ...panel.label }) }, panel.id))), _jsx(Item, { icon: _jsx(PersonIcon, {}), panel: p.AccountDetails, children: _jsx(Trans, { message: "Account details" }) }), !allSocialsDisabled && (_jsx(Item, { icon: _jsx(LoginIcon, {}), panel: p.SocialLogin, children: _jsx(Trans, { message: "Social login" }) })), _jsx(Item, { icon: _jsx(LockIcon, {}), panel: p.Password, children: _jsx(Trans, { message: "Password" }) }), _jsx(Item, { icon: _jsx(PhonelinkLockIcon, {}), panel: p.TwoFactor, children: _jsx(Trans, { message: "Two factor authentication" }) }), _jsx(Item, { icon: _jsx(DevicesIcon, {}), panel: p.Sessions, children: _jsx(Trans, { message: "Active sessions" }) }), _jsx(Item, { icon: _jsx(LanguageIcon, {}), panel: p.LocationAndLanguage, children: _jsx(Trans, { message: "Location and language" }) }), api?.integrated && hasPermission('api.access') ? (_jsx(Item, { icon: _jsx(ApiIcon, {}), panel: p.Developers, children: _jsx(Trans, { message: "Developers" }) })) : null, _jsx(Item, { icon: _jsx(DangerousIcon, {}), panel: p.DeleteAccount, children: _jsx(Trans, { message: "Delete account" }) })] }) }));
}
function Item({ children, icon, isLast, panel }) {
    return (_jsx(ListItem, { startIcon: icon, className: isLast ? undefined : 'mb-10', onSelected: () => {
            const panelEl = document.querySelector(`#${panel}`);
            if (panelEl) {
                panelEl.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                });
            }
        }, children: children }));
}

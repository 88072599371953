import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useUser } from '@common/auth/ui/use-user';
import { Trans } from '@ui/i18n/trans';
import { Button } from '@ui/buttons/button';
import { useResendVerificationEmail } from '@common/auth/requests/use-resend-verification-email';
import { useIsDarkMode } from '@ui/themes/use-is-dark-mode';
import { useSettings } from '@ui/settings/use-settings';
import { useLogout } from '@common/auth/requests/logout';
import { Form } from '@ui/forms/form';
import { useForm } from 'react-hook-form';
import { FormTextField } from '@ui/forms/input-field/text-field/text-field';
import { useTrans } from '@ui/i18n/use-trans';
import { message } from '@ui/i18n/message';
import { KeyboardArrowLeftIcon } from '@ui/icons/material/KeyboardArrowLeft';
import { useValidateEmailVerificationOtp, } from '@common/auth/requests/use-validate-email-verification-otp';
import { createBrowserRouter } from 'react-router';
import { FullPageLoader } from '@ui/progress/full-page-loader';
export const verifyEmailRouter = createBrowserRouter([
    {
        path: '*',
        element: _jsx(EmailVerificationPage, {}),
        hydrateFallbackElement: _jsx(FullPageLoader, { screen: true }),
    },
]);
export function EmailVerificationPage() {
    const { trans } = useTrans();
    const { data } = useUser('me');
    const resendEmail = useResendVerificationEmail();
    const { branding: { logo_light, logo_dark }, } = useSettings();
    const isDarkMode = useIsDarkMode();
    const logoSrc = isDarkMode ? logo_light : logo_dark;
    const logout = useLogout();
    const form = useForm();
    const validateOtp = useValidateEmailVerificationOtp(form);
    return (_jsx("div", { className: "flex min-h-screen w-screen bg-alt p-24", children: _jsxs("div", { className: "mx-auto mt-40 max-w-440", children: [_jsx(Button, { variant: "outline", onClick: () => logout.mutate(), startIcon: _jsx(KeyboardArrowLeftIcon, {}), size: "xs", className: "mb-54 mr-auto", children: _jsx(Trans, { message: "Logout" }) }), logoSrc && (_jsx("img", { src: logoSrc, alt: "Site logo", className: "mx-auto mb-44 block h-42 w-auto" })), _jsxs("div", { className: "text-center", children: [_jsx("h1", { className: "mb-24 text-3xl", children: _jsx(Trans, { message: "Verify your email" }) }), _jsx("h2", { className: "text-lg", children: _jsx(Trans, { message: "Enter the verification code we sent to :email", values: { email: maskEmailAddress(data?.user.email) } }) }), _jsxs(Form, { form: form, onSubmit: values => validateOtp.mutate(values), className: "my-16", children: [_jsx(FormTextField, { name: "code", label: _jsx(Trans, { message: "Code" }), placeholder: trans(message('Enter your verification code')), autoFocus: true, autoComplete: "one-time-code", autoCorrect: "off", autoCapitalize: "off", maxLength: 6, inputMode: "numeric", required: true }), _jsx(Button, { type: "submit", variant: "flat", color: "primary", size: "md", className: "mt-24 w-full", disabled: validateOtp.isPending, children: _jsx(Trans, { message: "Next" }) })] }), _jsx("div", { className: "mb-24 text-sm", children: _jsx(Trans, { message: "If you don't see the email in your inbox, check your spam folder and promotions tab. If you still don't see it, <a>request a resend</a>.", values: {
                                    a: text => (_jsx(Button, { variant: "link", color: "primary", disabled: resendEmail.isPending || !data?.user.email, onClick: () => {
                                            resendEmail.mutate({ email: data.user.email });
                                        }, children: text })),
                                } }) })] })] }) }));
}
function maskEmailAddress(email) {
    if (!email)
        return '*******************';
    const [username, domain] = email.split('@');
    return `${username.slice(0, 2)}****@${domain}`;
}

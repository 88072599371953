import { jsx as _jsx } from "react/jsx-runtime";
import { auth, useAuth } from '../use-auth';
import { Navigate, Outlet, replace } from 'react-router';
export function SubscribedRoute({ children }) {
    const { isSubscribed } = useAuth();
    if (!isSubscribed) {
        return _jsx(Navigate, { to: "/pricing", replace: true });
    }
    return children || _jsx(Outlet, {});
}
export function subscribedGuard() {
    if (!auth.isSubscribed) {
        return replace('/pricing');
    }
    return null;
}

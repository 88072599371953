import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link, useLocation, useSearchParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { FormTextField } from '@ui/forms/input-field/text-field/text-field';
import { Button } from '@ui/buttons/button';
import { Form } from '@ui/forms/form';
import { useLogin } from '../requests/use-login';
import { FormCheckbox } from '@ui/forms/toggle/checkbox';
import { LinkStyle } from '@ui/buttons/external-link';
import { SocialAuthSection } from './social-auth-section';
import { AuthLayout } from './auth-layout/auth-layout';
import { Trans } from '@ui/i18n/trans';
import { StaticPageTitle } from '../../seo/static-page-title';
import { useContext } from 'react';
import { SiteConfigContext, } from '../../core/settings/site-config-context';
import { useSettings } from '@ui/settings/use-settings';
export function LoginPage({ onTwoFactorChallenge }) {
    const [searchParams] = useSearchParams();
    const { pathname } = useLocation();
    const isWorkspaceLogin = pathname.includes('workspace');
    const searchParamsEmail = searchParams.get('email') || undefined;
    const { branding, registration, site, social } = useSettings();
    const siteConfig = useContext(SiteConfigContext);
    const demoDefaults = site.demo && !searchParamsEmail ? getDemoFormDefaults(siteConfig) : {};
    const form = useForm({
        defaultValues: { remember: true, email: searchParamsEmail, ...demoDefaults },
    });
    const login = useLogin(form);
    const heading = isWorkspaceLogin ? (_jsx(Trans, { values: { siteName: branding?.site_name }, message: "To join your team on :siteName, login to your account" })) : (_jsx(Trans, { message: "Sign in to your account" }));
    const message = !registration?.disable && (_jsx(Trans, { values: {
            a: parts => (_jsx(Link, { className: LinkStyle, to: "/register", children: parts })),
        }, message: "Don't have an account? <a>Sign up.</a>" }));
    const isInvalid = !!Object.keys(form.formState.errors).length;
    return (_jsxs(AuthLayout, { heading: heading, message: message, children: [_jsx(StaticPageTitle, { children: _jsx(Trans, { message: "Login" }) }), _jsxs(Form, { form: form, onSubmit: payload => {
                    login.mutate(payload, {
                        onSuccess: response => {
                            if (response.two_factor) {
                                onTwoFactorChallenge();
                            }
                        },
                    });
                }, children: [_jsx(FormTextField, { className: "mb-32", name: "email", type: "email", label: _jsx(Trans, { message: "Email" }), disabled: !!searchParamsEmail, invalid: isInvalid, required: true }), _jsx(FormTextField, { className: "mb-12", name: "password", type: "password", label: _jsx(Trans, { message: "Password" }), invalid: isInvalid, labelSuffix: _jsx(Link, { className: LinkStyle, to: "/forgot-password", tabIndex: -1, children: _jsx(Trans, { message: "Forgot your password?" }) }), required: true }), _jsx(FormCheckbox, { name: "remember", className: "mb-32 block", children: _jsx(Trans, { message: "Stay signed in for a month" }) }), _jsx(Button, { className: "block w-full", type: "submit", variant: "flat", color: "primary", size: "md", disabled: login.isPending, children: _jsx(Trans, { message: "Continue" }) })] }), _jsx(SocialAuthSection, { dividerMessage: social?.compact_buttons ? (_jsx(Trans, { message: "Or sign in with" })) : (_jsx(Trans, { message: "OR" })) })] }));
}
function getDemoFormDefaults(siteConfig) {
    if (siteConfig.demo.loginPageDefaults === 'randomAccount') {
        // random number between 0 and 100, padded to 3 digits
        const number = Math.floor(Math.random() * 100) + 1;
        const paddedNumber = String(number).padStart(3, '0');
        return {
            email: `admin@demo${paddedNumber}.com`,
            password: 'admin',
        };
    }
    else {
        return {
            email: siteConfig.demo.email ?? 'admin@admin.com',
            password: siteConfig.demo.password ?? 'admin',
        };
    }
}

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { produce } from 'immer';
import { useNotificationSubscriptions } from './requests/notification-subscriptions';
import { Navbar } from '../../ui/navigation/navbar/navbar';
import { ProgressCircle } from '@ui/progress/progress-circle';
import { Checkbox } from '@ui/forms/toggle/checkbox';
import { useUpdateNotificationSettings } from './requests/update-notification-settings';
import { Button } from '@ui/buttons/button';
import { toast } from '@ui/toast/toast';
import { Trans } from '@ui/i18n/trans';
import { message } from '@ui/i18n/message';
import { useSettings } from '@ui/settings/use-settings';
import { Navigate } from 'react-router';
export function NotificationSettingsPage() {
    const { notif } = useSettings();
    const updateSettings = useUpdateNotificationSettings();
    const { data, isFetched } = useNotificationSubscriptions();
    const [selection, setSelection] = useState();
    useEffect(() => {
        if (data && !selection) {
            const initialSelection = {};
            const initialValue = {};
            data.available_channels.forEach(channel => {
                initialValue[channel] = false;
            });
            data.subscriptions.forEach(group => {
                group.subscriptions.forEach(subscription => {
                    const backendValue = data.user_selections.find(s => s.notif_id === subscription.notif_id);
                    initialSelection[subscription.notif_id] = backendValue?.channels || {
                        ...initialValue,
                    };
                });
            });
            setSelection(initialSelection);
        }
    }, [data, selection]);
    if (!notif.subs.integrated || (data && data.subscriptions.length === 0)) {
        return _jsx(Navigate, { to: "/", replace: true });
    }
    return (_jsxs("div", { className: "min-h-screen bg-alt", children: [_jsx(Navbar, { menuPosition: "notifications-page" }), !isFetched || !data || !selection ? (_jsx("div", { className: "container mx-auto my-100 flex justify-center", children: _jsx(ProgressCircle, { size: "md", isIndeterminate: true, "aria-label": "Loading subscriptions..." }) })) : (_jsx("div", { className: "container mx-auto my-20 px-10 md:my-40 md:px-20", children: _jsxs("div", { className: "rounded border bg-paper px-20 pb-30 pt-20", children: [data.subscriptions.map(group => (_jsxs("div", { className: "mb-10 text-sm", children: [_jsx(GroupRow, { group: group, allChannels: data?.available_channels, selection: selection, setSelection: setSelection }, group.group_name), group.subscriptions.map(subscription => (_jsx(SubscriptionRow, { subscription: subscription, selection: selection, setSelection: setSelection, allChannels: data?.available_channels }, subscription.notif_id)))] }, group.group_name))), _jsx(Button, { className: "ml-10 mt-20", variant: "flat", color: "primary", disabled: updateSettings.isPending, onClick: () => {
                                updateSettings.mutate(Object.entries(selection).map(([notifId, channels]) => {
                                    return { notif_id: notifId, channels };
                                }));
                            }, children: _jsx(Trans, { message: "Update preferences" }) })] }) }))] }));
}
function GroupRow({ group, allChannels, selection, setSelection, }) {
    const toggleAll = (channelName, value) => {
        const nextState = produce(selection, draftState => {
            Object.keys(selection).forEach(notifId => {
                draftState[notifId][channelName] = value;
            });
        });
        setSelection(nextState);
    };
    const checkboxes = (_jsx("div", { className: "ml-auto flex items-center gap-40 max-md:hidden", children: allChannels.map(channelName => {
            const allSelected = Object.values(selection).every(s => s[channelName]);
            const someSelected = !allSelected && Object.values(selection).some(s => s[channelName]);
            return (_jsx(Checkbox, { orientation: "vertical", isIndeterminate: someSelected, checked: allSelected, onChange: async (e) => {
                    if (channelName === 'browser') {
                        const granted = await requestBrowserPermission();
                        toggleAll(channelName, !granted ? false : !allSelected);
                    }
                    else {
                        toggleAll(channelName, !allSelected);
                    }
                }, children: _jsx(Trans, { message: channelName }) }, channelName));
        }) }));
    return (_jsxs("div", { className: "flex items-center border-b p-10", children: [_jsx("div", { className: "font-medium", children: _jsx(Trans, { message: group.group_name }) }), checkboxes] }));
}
function SubscriptionRow({ subscription, allChannels, selection, setSelection, }) {
    const notifId = subscription.notif_id;
    const toggleChannel = (channelName, value) => {
        const nextState = produce(selection, draftState => {
            draftState[subscription.notif_id][channelName] = value;
        });
        setSelection(nextState);
    };
    return (_jsxs("div", { className: "items-center border-b py-10 pl-8 pr-10 md:flex md:pl-20", children: [_jsx("div", { className: "pb-14 font-semibold md:pb-0 md:font-normal", children: _jsx(Trans, { message: subscription.name }) }), _jsx("div", { className: "ml-auto flex items-center gap-40", children: allChannels.map(channelName => (_jsx(Checkbox, { orientation: "vertical", checked: selection[notifId][channelName], onChange: async (e) => {
                        const newValue = !selection[notifId][channelName];
                        if (channelName === 'browser') {
                            const granted = await requestBrowserPermission();
                            toggleChannel(channelName, !granted ? false : newValue);
                        }
                        else {
                            toggleChannel(channelName, newValue);
                        }
                    }, "aria-label": channelName, children: _jsx("div", { className: "md:invisible md:h-0", children: _jsx(Trans, { message: channelName }) }) }, channelName))) })] }));
}
function requestBrowserPermission() {
    if (Notification.permission === 'granted') {
        return Promise.resolve(true);
    }
    if (Notification.permission === 'denied') {
        toast.danger(message('Notifications blocked. Please enable them for this site from browser settings.'));
        return Promise.resolve(false);
    }
    return Notification.requestPermission().then(permission => {
        return permission === 'granted';
    });
}

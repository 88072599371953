import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useUser } from '@common/auth/ui/use-user';
import { Trans } from '@ui/i18n/trans';
import { Button } from '@ui/buttons/button';
import { useIsDarkMode } from '@ui/themes/use-is-dark-mode';
import { useSettings } from '@ui/settings/use-settings';
import { useLogout } from '@common/auth/requests/logout';
import { useTrans } from '@ui/i18n/use-trans';
import { KeyboardArrowLeftIcon } from '@ui/icons/material/KeyboardArrowLeft';
import { createBrowserRouter } from 'react-router';
import { FullPageLoader } from '@ui/progress/full-page-loader';
export const userSuspendedRouter = createBrowserRouter([
    {
        path: '*',
        element: _jsx(UserSuspendedPage, {}),
        hydrateFallbackElement: _jsx(FullPageLoader, { screen: true }),
    },
]);
export function UserSuspendedPage() {
    const { trans } = useTrans();
    const { data } = useUser('me');
    const { branding: { logo_light, logo_dark, site_name }, } = useSettings();
    const isDarkMode = useIsDarkMode();
    const logoSrc = isDarkMode ? logo_light : logo_dark;
    const logout = useLogout();
    return (_jsx("div", { className: "flex min-h-screen w-screen bg-alt p-24", children: _jsxs("div", { className: "mx-auto mt-40 max-w-440", children: [_jsx(Button, { variant: "outline", onClick: () => logout.mutate(), startIcon: _jsx(KeyboardArrowLeftIcon, {}), size: "xs", className: "mb-54 mr-auto", children: _jsx(Trans, { message: "Logout" }) }), logoSrc && (_jsx("img", { src: logoSrc, alt: "Site logo", className: "mx-auto mb-44 block h-42 w-auto" })), _jsxs("div", { className: "text-center", children: [_jsx("h1", { className: "mb-24 text-3xl", children: _jsx(Trans, { message: "Your account is suspended" }) }), _jsx("p", { className: "text-base", children: _jsx(Trans, { message: "You can't open :name because your account is suspended. Contact the Admin to re-activate your account.", values: { name: site_name } }) })] })] }) }));
}

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Trans } from '@ui/i18n/trans';
import { Navbar } from '@common/ui/navigation/navbar/navbar';
import { Form } from '@ui/forms/form';
import { useForm } from 'react-hook-form';
import { useSubmitContactForm, } from './use-submit-contact-form';
import { FormTextField } from '@ui/forms/input-field/text-field/text-field';
import { Button } from '@ui/buttons/button';
import { useRecaptcha } from '../recaptcha/use-recaptcha';
import { StaticPageTitle } from '../seo/static-page-title';
import { Footer } from '@common/ui/footer/footer';
export function ContactUsPage() {
    const form = useForm();
    const submitForm = useSubmitContactForm(form);
    const { verify, isVerifying } = useRecaptcha('contact');
    return (_jsxs("div", { className: "flex min-h-screen flex-col bg-alt", children: [_jsx(StaticPageTitle, { children: _jsx(Trans, { message: "Contact us" }) }), _jsx(Navbar, { className: "sticky top-0 flex-shrink-0", menuPosition: "contact-us-page" }), _jsx("div", { className: "container mx-auto flex flex-auto items-center justify-center p-24 md:p-40", children: _jsxs("div", { className: "max-w-620 rounded border bg-paper p-24", children: [_jsx("h1", { className: "text-2xl", children: _jsx(Trans, { message: "Contact us" }) }), _jsx("p", { className: "mb-30 mt-4 text-sm", children: _jsx(Trans, { message: "Please use the form below to send us a message and we'll get back to you as soon as possible." }) }), _jsxs(Form, { form: form, onSubmit: async (value) => {
                                const isValid = await verify();
                                if (isValid) {
                                    submitForm.mutate(value);
                                }
                            }, children: [_jsx(FormTextField, { label: _jsx(Trans, { message: "Name" }), name: "name", required: true, className: "mb-20" }), _jsx(FormTextField, { label: _jsx(Trans, { message: "Email" }), name: "email", required: true, type: "email", className: "mb-20" }), _jsx(FormTextField, { label: _jsx(Trans, { message: "Message" }), name: "message", required: true, inputElementType: "textarea", className: "mb-20", rows: 8 }), _jsx(Button, { type: "submit", variant: "flat", color: "primary", disabled: submitForm.isPending || isVerifying, children: _jsx(Trans, { message: "Send" }) })] })] }) }), _jsx(Footer, { className: "container mx-auto flex-shrink-0 px-24" })] }));
}

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { Trans } from '@ui/i18n/trans';
import { CustomMenuItem } from '../../menus/custom-menu';
import { Button } from '@ui/buttons/button';
import { useSettings } from '@ui/settings/use-settings';
import { useState } from 'react';
import { getBootstrapData } from '@ui/bootstrap-data/bootstrap-data-store';
import { useCookie } from '@ui/utils/hooks/use-cookie';
export function CookieNotice() {
    const { cookie_notice } = useSettings();
    const [, setCookie] = useCookie('cookie_notice');
    const [alreadyAccepted, setAlreadyAccepted] = useState(() => {
        return !getBootstrapData().show_cookie_notice;
    });
    if (!cookie_notice?.enable || alreadyAccepted) {
        return null;
    }
    return (_jsxs("div", { className: clsx('fixed z-50 flex w-full justify-center gap-14 bg-toast p-14 text-sm text-white shadow max-md:flex-col md:items-center md:gap-30', cookie_notice?.position == 'top' ? 'top-0' : 'bottom-0'), children: [_jsx(Trans, { message: "We use cookies to optimize site functionality and provide you with the\n      best possible experience." }), _jsx(InfoLink, {}), _jsx(Button, { variant: "flat", color: "primary", size: "xs", className: "max-w-100", onClick: () => {
                    setCookie('true', { days: 30, path: '/' });
                    setAlreadyAccepted(true);
                }, children: _jsx(Trans, { message: "OK" }) })] }));
}
function InfoLink() {
    const { cookie_notice } = useSettings();
    if (!cookie_notice?.button?.label) {
        return null;
    }
    return (_jsx(CustomMenuItem, { className: () => 'text-primary-light hover:underline', item: cookie_notice?.button }));
}

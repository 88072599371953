import { jsx as _jsx } from "react/jsx-runtime";
import { Trans } from '@ui/i18n/trans';
export function MixedText({ value }) {
    if (!value) {
        return null;
    }
    if (typeof value === 'string') {
        return _jsx(Trans, { message: value });
    }
    return _jsx(Trans, { ...value });
}

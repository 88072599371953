import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link, useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { FormTextField } from '@ui/forms/input-field/text-field/text-field';
import { Button } from '@ui/buttons/button';
import { Form } from '@ui/forms/form';
import { LinkStyle } from '@ui/buttons/external-link';
import { AuthLayout } from './auth-layout/auth-layout';
import { useResetPassword, } from '../requests/reset-password';
import { Trans } from '@ui/i18n/trans';
import { StaticPageTitle } from '../../seo/static-page-title';
export function ResetPasswordPage() {
    const { token } = useParams();
    const form = useForm({ defaultValues: { token } });
    const resetPassword = useResetPassword(form);
    const heading = _jsx(Trans, { message: "Reset your account password" });
    const message = (_jsx(Trans, { values: {
            a: parts => (_jsx(Link, { className: LinkStyle, to: "/register", children: parts })),
        }, message: "Don't have an account? <a>Sign up.</a>" }));
    return (_jsxs(AuthLayout, { heading: heading, message: message, children: [_jsx(StaticPageTitle, { children: _jsx(Trans, { message: "Reset Password" }) }), _jsxs(Form, { form: form, onSubmit: payload => {
                    resetPassword.mutate(payload);
                }, children: [_jsx(FormTextField, { className: "mb-32", name: "email", type: "email", label: _jsx(Trans, { message: "Email" }), required: true }), _jsx(FormTextField, { className: "mb-32", name: "password", type: "password", label: _jsx(Trans, { message: "New password" }), required: true }), _jsx(FormTextField, { className: "mb-32", name: "password_confirmation", type: "password", label: _jsx(Trans, { message: "Confirm password" }), required: true }), _jsx(Button, { className: "block w-full", type: "submit", variant: "flat", color: "primary", size: "md", disabled: resetPassword.isPending, children: _jsx(Trans, { message: "Reset password" }) })] })] }));
}

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link, useSearchParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { FormTextField } from '@ui/forms/input-field/text-field/text-field';
import { Button } from '@ui/buttons/button';
import { Form } from '@ui/forms/form';
import { LinkStyle } from '@ui/buttons/external-link';
import { AuthLayout } from './auth-layout/auth-layout';
import { useSendPasswordResetEmail, } from '../requests/send-reset-password-email';
import { Trans } from '@ui/i18n/trans';
import { StaticPageTitle } from '../../seo/static-page-title';
import { useSettings } from '@ui/settings/use-settings';
export function ForgotPasswordPage() {
    const { registration } = useSettings();
    const [searchParams] = useSearchParams();
    const searchParamsEmail = searchParams.get('email') || undefined;
    const form = useForm({
        defaultValues: { email: searchParamsEmail },
    });
    const sendEmail = useSendPasswordResetEmail(form);
    const message = !registration?.disable && (_jsx(Trans, { values: {
            a: parts => (_jsx(Link, { className: LinkStyle, to: "/register", children: parts })),
        }, message: "Don't have an account? <a>Sign up.</a>" }));
    return (_jsxs(AuthLayout, { message: message, children: [_jsx(StaticPageTitle, { children: _jsx(Trans, { message: "Forgot Password" }) }), _jsxs(Form, { form: form, onSubmit: payload => {
                    sendEmail.mutate(payload);
                }, children: [_jsx("div", { className: "mb-32 text-sm", children: _jsx(Trans, { message: "Enter your email address below and we will send you a link to reset or create your password." }) }), _jsx(FormTextField, { disabled: !!searchParamsEmail, className: "mb-32", name: "email", type: "email", autoComplete: "off", autoCorrect: "off", spellCheck: "false", label: _jsx(Trans, { message: "Email" }), required: true }), _jsx(Button, { className: "block w-full", type: "submit", variant: "flat", color: "primary", size: "md", disabled: sendEmail.isPending, children: _jsx(Trans, { message: "Continue" }) })] })] }));
}

import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from 'react';
import { applyThemeToDom } from '@ui/themes/utils/apply-theme-to-dom';
import { ThemeSelectorContext, } from '@ui/themes/theme-selector-context';
import { useSettings } from '@ui/settings/use-settings';
import { useCookie } from '@ui/utils/hooks/use-cookie';
import { useBootstrapDataStore } from '@ui/bootstrap-data/bootstrap-data-store';
import { usePreferredColorScheme } from '@ui/themes/use-preferred-color-scheme';
import { themeEl } from '@ui/root-el';
const STORAGE_KEY = 'be-active-theme';
export function ThemeProvider({ children }) {
    const settings = useSettings();
    const preferredColorScheme = usePreferredColorScheme();
    const data = useBootstrapDataStore(s => s.data);
    const allThemes = data.themes;
    const [cookieThemeId, setSelectedThemeId] = useCookie(STORAGE_KEY);
    const [forcedThemeId, setForceThemeId] = useState(null);
    const selectedThemeId = forcedThemeId ?? cookieThemeId;
    const selectThemeTemporarily = useCallback((themeId) => {
        setForceThemeId(themeId);
    }, []);
    const resolveTheme = useCallback((themeId) => {
        // set theme based on user preference
        if (themeId == 0 || themeId === 'system') {
            return allThemes.find(t => preferredColorScheme === 'light' ? t.default_light : t.default_dark);
        }
        if (themeId === 'light' || themeId === 'dark') {
            return allThemes.find(t => t.default_light === (themeId === 'light'));
        }
        return allThemes.find(t => t.id == themeId);
    }, [allThemes, preferredColorScheme]);
    let selectedTheme = selectedThemeId
        ? resolveTheme(selectedThemeId)
        : resolveTheme(settings.themes?.default_id ?? 0);
    if (!selectedTheme) {
        selectedTheme = allThemes[0];
    }
    // if selected theme is different then the one that was set
    // with server render, set new css variables, this will only
    // happen if user has not selected theme manually and default theme is set to "system"
    useEffect(() => {
        const currentThemeId = themeEl.dataset.themeId;
        if (selectedTheme && `${selectedTheme.id}` !== `${currentThemeId}`) {
            applyThemeToDom(selectedTheme);
        }
        // only apply theme if id has changed
    }, [selectedTheme.id]);
    const contextValue = useMemo(() => {
        return {
            allThemes,
            selectedTheme: selectedTheme,
            selectThemeTemporarily,
            selectTheme: id => {
                const theme = resolveTheme(id);
                if (theme) {
                    setSelectedThemeId(`${theme.id}`);
                    applyThemeToDom(theme);
                }
            },
        };
    }, [
        allThemes,
        selectedTheme,
        setSelectedThemeId,
        resolveTheme,
        selectThemeTemporarily,
    ]);
    return (_jsx(ThemeSelectorContext.Provider, { value: contextValue, children: children }));
}

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSettings } from '@ui/settings/use-settings';
import { useNavigate } from '@common/ui/navigation/use-navigate';
import { Menu, MenuTrigger } from '@ui/menu/menu-trigger';
import { IconButton } from '@ui/buttons/icon-button';
import { PersonIcon } from '@ui/icons/material/Person';
import { Item } from '@ui/forms/listbox/item';
import { Trans } from '@ui/i18n/trans';
import { Link } from 'react-router';
import { Button } from '@ui/buttons/button';
import { Fragment } from 'react';
export function NavbarAuthButtons({ primaryButtonColor, navbarColor, }) {
    if (!primaryButtonColor) {
        primaryButtonColor = navbarColor === 'primary' ? 'paper' : 'primary';
    }
    return (_jsxs(Fragment, { children: [_jsx(MobileButtons, {}), _jsx(DesktopButtons, { primaryButtonColor: primaryButtonColor })] }));
}
function DesktopButtons({ primaryButtonColor }) {
    const { registration } = useSettings();
    return (_jsxs("div", { className: "text-sm max-md:hidden", children: [!registration?.disable && (_jsx(Button, { elementType: Link, to: "/register", variant: "text", className: "mr-10", children: _jsx(Trans, { message: "Register" }) })), _jsx(Button, { elementType: Link, to: "/login", variant: "raised", color: primaryButtonColor, children: _jsx(Trans, { message: "Login" }) })] }));
}
function MobileButtons() {
    const { registration } = useSettings();
    const navigate = useNavigate();
    return (_jsxs(MenuTrigger, { children: [_jsx(IconButton, { size: "md", className: "md:hidden", children: _jsx(PersonIcon, {}) }), _jsxs(Menu, { children: [_jsx(Item, { value: "login", onSelected: () => navigate('/login'), children: _jsx(Trans, { message: "Login" }) }), !registration?.disable && (_jsx(Item, { value: "register", onSelected: () => navigate('/register'), children: _jsx(Trans, { message: "Register" }) }))] })] }));
}

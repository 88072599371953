import { jsx as _jsx } from "react/jsx-runtime";
import { Fragment, memo } from 'react';
import { useNumberFormatter } from '@ui/i18n/use-number-formatter';
export const FormattedCurrency = memo(({ value, currency }) => {
    const formatter = useNumberFormatter({
        style: 'currency',
        currency,
        currencyDisplay: 'narrowSymbol',
    });
    if (isNaN(value)) {
        value = 0;
    }
    return _jsx(Fragment, { children: formatter.format(value) });
});

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useForm } from 'react-hook-form';
import { Fragment } from 'react';
import { useConnectSocialWithPassword, } from '../requests/connect-social-with-password';
import { useDialogContext } from '@ui/overlays/dialog/dialog-context';
import { Dialog } from '@ui/overlays/dialog/dialog';
import { DialogHeader } from '@ui/overlays/dialog/dialog-header';
import { DialogBody } from '@ui/overlays/dialog/dialog-body';
import { DialogFooter } from '@ui/overlays/dialog/dialog-footer';
import { Button } from '@ui/buttons/button';
import { IconButton } from '@ui/buttons/icon-button';
import { DialogTrigger } from '@ui/overlays/dialog/dialog-trigger';
import clsx from 'clsx';
import { EnvatoIcon } from '@ui/icons/social/envato';
import { useAllSocialLoginsDisabled } from '@common/auth/ui/use-all-social-logins-disabled';
import { useSettings } from '@ui/settings/use-settings';
import { message } from '@ui/i18n/message';
import { useNavigate } from '@common/ui/navigation/use-navigate';
import { useAuth } from '@common/auth/use-auth';
import { useSocialLogin, } from '@common/auth/requests/use-social-login';
import { GoogleIcon } from '@ui/icons/social/google';
import { FacebookIcon } from '@ui/icons/social/facebook';
import { TwitterIcon } from '@ui/icons/social/twitter';
import { Trans } from '@ui/i18n/trans';
import { Form } from '@ui/forms/form';
import { FormTextField } from '@ui/forms/input-field/text-field/text-field';
import { useTrans } from '@ui/i18n/use-trans';
const googleLabel = message('Continue with google');
const facebookLabel = message('Continue with facebook');
const twitterLabel = message('Continue with twitter');
const envatoLabel = message('Continue with envato');
export function SocialAuthSection({ dividerMessage }) {
    const { social } = useSettings();
    const navigate = useNavigate();
    const { getRedirectUri } = useAuth();
    const { loginWithSocial, requestingPassword, setIsRequestingPassword } = useSocialLogin();
    if (useAllSocialLoginsDisabled()) {
        return null;
    }
    const handleSocialLogin = async (service) => {
        const e = await loginWithSocial(service);
        if (e?.status === 'SUCCESS' || e?.status === 'ALREADY_LOGGED_IN') {
            navigate(getRedirectUri(), { replace: true });
        }
    };
    return (_jsxs(Fragment, { children: [_jsx("div", { className: "relative my-20 text-center before:absolute before:left-0 before:top-1/2 before:h-1 before:w-full before:-translate-y-1/2 before:bg-divider", children: _jsx("span", { className: "relative z-10 bg-paper px-10 text-sm text-muted", children: dividerMessage }) }), _jsxs("div", { className: clsx('flex items-center justify-center gap-14', !social?.compact_buttons && 'flex-col'), children: [social?.google?.enable ? (_jsx(SocialLoginButton, { label: googleLabel, icon: _jsx(GoogleIcon, { viewBox: "0 0 48 48" }), onClick: () => handleSocialLogin('google') })) : null, social?.facebook?.enable ? (_jsx(SocialLoginButton, { label: facebookLabel, icon: _jsx(FacebookIcon, { className: "text-facebook" }), onClick: () => handleSocialLogin('facebook') })) : null, social?.twitter?.enable ? (_jsx(SocialLoginButton, { label: twitterLabel, icon: _jsx(TwitterIcon, { className: "text-twitter" }), onClick: () => handleSocialLogin('twitter') })) : null, social?.envato?.enable ? (_jsx(SocialLoginButton, { label: envatoLabel, icon: _jsx(EnvatoIcon, { viewBox: "0 0 50 50", className: "text-envato" }), onClick: () => handleSocialLogin('envato') })) : null] }), _jsx(DialogTrigger, { type: "modal", isOpen: requestingPassword, onOpenChange: setIsRequestingPassword, children: _jsx(RequestPasswordDialog, {}) })] }));
}
function RequestPasswordDialog() {
    const form = useForm();
    const { formId } = useDialogContext();
    const connect = useConnectSocialWithPassword(form);
    return (_jsxs(Dialog, { children: [_jsx(DialogHeader, { children: _jsx(Trans, { message: "Password required" }) }), _jsxs(DialogBody, { children: [_jsx("div", { className: "mb-30 text-sm text-muted", children: _jsx(Trans, { message: "An account with this email address already exists. If you want to connect the two accounts, enter existing account password." }) }), _jsx(Form, { form: form, id: formId, onSubmit: payload => {
                            connect.mutate(payload);
                        }, children: _jsx(FormTextField, { autoFocus: true, name: "password", type: "password", required: true, label: _jsx(Trans, { message: "Password" }) }) })] }), _jsxs(DialogFooter, { children: [_jsx(Button, { variant: "text", children: _jsx(Trans, { message: "Cancel" }) }), _jsx(Button, { type: "submit", form: formId, variant: "flat", color: "primary", disabled: connect.isPending, children: _jsx(Trans, { message: "Connect" }) })] })] }));
}
function SocialLoginButton({ onClick, label, icon }) {
    const { trans } = useTrans();
    const settings = useSettings();
    if (settings.social?.compact_buttons) {
        return (_jsx(IconButton, { variant: "outline", "aria-label": trans(label), onClick: onClick, children: icon }));
    }
    return (_jsx(Button, { variant: "outline", startIcon: icon, onClick: onClick, className: "min-h-42 w-full", children: _jsx("span", { className: "min-w-160 text-start", children: _jsx(Trans, { ...label }) }) }));
}

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useForm } from 'react-hook-form';
import { useId } from 'react';
import { Form } from '@ui/forms/form';
import { AccountSettingsPanel } from '@common/auth/ui/account-settings/account-settings-panel';
import { FormTextField } from '@ui/forms/input-field/text-field/text-field';
import { useUpdatePassword } from './use-update-password';
import { Button } from '@ui/buttons/button';
import { Trans } from '@ui/i18n/trans';
import { AccountSettingsId } from '@common/auth/ui/account-settings/account-settings-sidenav';
export function ChangePasswordPanel() {
    const form = useForm();
    const formId = useId();
    const updatePassword = useUpdatePassword(form);
    return (_jsx(AccountSettingsPanel, { id: AccountSettingsId.Password, title: _jsx(Trans, { message: "Update password" }), actions: _jsx(Button, { type: "submit", form: formId, variant: "flat", color: "primary", disabled: !form.formState.isValid || updatePassword.isPending, children: _jsx(Trans, { message: "Update password" }) }), children: _jsxs(Form, { form: form, id: formId, onSubmit: newValues => {
                updatePassword.mutate(newValues, {
                    onSuccess: () => {
                        form.reset();
                    },
                });
            }, children: [_jsx(FormTextField, { className: "mb-24", name: "current_password", label: _jsx(Trans, { message: "Current password" }), type: "password", autoComplete: "current-password", required: true }), _jsx(FormTextField, { className: "mb-24", name: "password", label: _jsx(Trans, { message: "New password" }), type: "password", autoComplete: "new-password", required: true }), _jsx(FormTextField, { name: "password_confirmation", label: _jsx(Trans, { message: "Confirm password" }), type: "password", autoComplete: "new-password", required: true })] }) }));
}
